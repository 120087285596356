<template>
  <div class="attempts">
    <div
      v-for="(item, index) in attempts"
      :key="index"
      :class="['item', { active: index == value }]"
      @click="$emit('input', index)"
    >
      <a-icon
        v-if="item.status === 'failed'"
        type="frown"
        theme="twoTone"
        two-tone-color="red"
      />
      <a-icon v-else type="smile" theme="twoTone" two-tone-color="#52c41a" />
      <span>重试 {{ index + 1 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attempts: Array,
    value: [String, Number]
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.attempts {
  display: flex;
  align-items: center;
  user-select: none;
  color: rgb(107, 107, 117);
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
    padding: 2px 8px;
    border-radius: 3px;
  }
  .item.active {
    background: @blue-2;
  }
  .anticon {
    margin-right: 6px;
    font-size: 14px;
  }
}
</style>
