<template>
  <Oper class="oper" :show-hover-bg="false" @click="() => $emit('add')">
    <a-icon type="plus" />
    <span>添加数据源</span>
  </Oper>
</template>

<script>
import Oper from "@/views/connections/components/common-btn";
export default {
  components: { Oper }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.oper {
  margin-right: 40px;
  color: @blue-6;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
}
</style>
