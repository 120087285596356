<template>
  <a-modal
    class="ndl-antd model-content-custom"
    :width="1000"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="confirmLoading"
    :ok-text="'完成'"
    @ok="save"
  >
    <ModelHeader slot="title" :img-src="connectorIcon" :title="connectorName" />
    <div class="model-body-small" ref="body">
      <StepsConfig
        ref="stepsConfig"
        :connection="connection"
        :not-navigation="true"
      />
    </div>
  </a-modal>
</template>

<script>
import ModelHeader from "@/views/connections/components/modal-common-header";
import { StepsConfig } from "@/views/connections/components/connection-editor";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  components: { ModelHeader, StepsConfig },
  provide() {
    const vm = this;
    return {
      getSkeletonBody() {
        return vm.$refs.body;
      }
    };
  },
  props: {
    connector: {
      type: Object,
      required: true
    },
    connection: {
      type: Object,
      required: true
    },
    $resolve: Function
  },
  data() {
    return {
      confirmLoading: false
    };
  },
  computed: {
    connectorIcon() {
      return connectorList.getConnectorIcon(this.connector.id);
    },
    connectorName() {
      return connectorList.getConnectorName(this.connector.id) + " - 连接设置";
    }
  },
  methods: {
    async save() {
      try {
        this.confirmLoading = true;
        await this.$refs.stepsConfig.next();
        this.$message.success("修改成功");
        this.$resolve();
      } catch (err) {
        const resData = err.data || {};
        this.$notification.error({
          message: "操作失败",
          description: resData.msg
        });
      } finally {
        this.confirmLoading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.model-content-custom /deep/ .ant-form.ant-form-vertical {
  margin: 36px auto;
  max-width: 900px;
}
</style>
