<template>
  <Editor
    v-model="connectorModel"
    ref="editor"
    :definition-id="connector.definitionId"
  >
    <a-button block type="primary" :loading="loading" @click="onSave()">
      更新设置
    </a-button>
  </Editor>
</template>

<script>
import Editor from "@/views/connections/components/connector-editor";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import connectorList from "@/views/connections/models/connectors";

export default {
  components: { Editor },
  props: {
    connector: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      connectorModel: cloneDeep(this.connector)
    };
  },
  methods: {
    async onSave() {
      if (!this.$refs.editor.validate()) {
        return;
      }
      try {
        this.loading = true;
        await connectorList.saveConnector(this.connectorModel);
        merge(this.connector, this.connectorModel);
        this.$message.success("更新成功");
      } catch (err) {
        const resData = err.data || {};
        this.$notification.error({
          message: "操作失败",
          description: resData.msg
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
