<template>
  <div v-if="!(editable && editing)" :title="value" @click="editing = true">
    <template v-if="value">{{ value }}</template>
    <span v-else class="empty-text">{{ emptyText }}</span>
  </div>
  <!-- 用 <component /> 渲染时 textarea 无法用 :value 绑定值，所以分开写。原因待查 -->
  <textarea
    v-focus
    v-else-if="multiRows"
    placeholder="请输入..."
    key="textarea"
    class="editor"
    :value="value"
    @blur="onBlur"
    @keydown.esc.stop="onEsc"
    @keydown.enter="onEnter"
  />
  <input
    v-else
    v-focus
    class="editor"
    key="input"
    :value="value"
    placeholder="请输入..."
    @blur="onBlur"
    @keydown.esc.stop="onEsc"
    @keydown.enter="onEnter"
  />
</template>

<script>
import { focus } from "@/utils/directives";

export default {
  props: {
    value: String,
    editable: Boolean,
    multiRows: Boolean,
    allowEmpty: Boolean,
    emptyText: String
  },
  directives: { focus },
  data() {
    return { editing: false };
  },
  methods: {
    onBlur(evt) {
      const value = evt.target.value;
      if (this.allowEmpty || value) {
        this.$emit("input", value);
        this.$emit("change", value);
      }
      this.editing = false;
    },
    onEsc(evt) {
      evt.target.value = this.value;
      evt.target.blur();
    },
    onEnter(evt) {
      if (!(this.multiRows && evt.shiftKey)) {
        evt.target.blur();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.editor {
  display: block;
  border: none;
  outline: none;
  resize: none;
  background: #f5f5f5 !important;
}
.empty-text {
  color: #bfbfbf;
  font-style: italic;
}
</style>
