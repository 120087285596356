import isPlainObject from "lodash/isPlainObject";

function toFieldName(path = [], name) {
  return [].concat(path, name).join(".");
}

/**
 * 将 properties 对象转为数组，以此结构为例
 * {
 *   "properties": {
 *     "createdAt": {
 *       "type": "integer"
 *     },
 *     "fields": {
 *       "type": "object",
 *       "properties": {
 *         "record_id": {
 *           "type": "number"
 *         }
 *       }
 *     }
 *   }
 * }
 * @param {Object} properties stream 的 propertie，json-schema 格式
 * @param {Array} fieldNamePath 路径前缀
 * @returns {Array} field list
 */
export default function flatten(properties, fieldNamePath = []) {
  if (!isPlainObject(properties)) {
    return [];
  }
  return Object.entries(properties).reduce((sources, item) => {
    return sources.concat(
      {
        ...item[1],
        // 字段名，如 record_id
        fieldName: item[0],
        // 包含完整路径的字段名 fields.record_id
        fieldNameAlias: toFieldName(fieldNamePath, item[0])
      },
      flatten(item[1].properties, fieldNamePath.concat(item[0]))
    );
  }, []);
}
