<template>
  <a @click="selectMode">
    {{ label }}
    <i class="ndl-icon-chevron-down"></i>
  </a>
</template>

<script>
import { localeMode, selectMode } from "./sync-modes";

export default {
  props: {
    config: {
      type: Object,
      required: true
    },
    stream: {
      type: Object,
      required: true
    },
    destinationModes: Array
  },
  computed: {
    label() {
      return localeMode(this.config);
    }
  },
  methods: {
    selectMode(evt) {
      const config = this.config;
      const stream = this.stream || {};
      selectMode({
        source: evt.target,
        inverse: { x: true, y: false },
        mode: [config.syncMode, config.destinationSyncMode],
        sourceModes: stream.supportedSyncModes || [],
        destinationModes: this.destinationModes || []
      }).then(mode => {
        const [syncMode, destinationSyncMode] = mode;
        config.syncMode = syncMode;
        config.destinationSyncMode = destinationSyncMode;
        // 切换同步模式时清空 cursorField 和 primaryKey
        config.cursorField =
          syncMode === "incremental" ? stream.defaultCursorField || [] : [];
        config.primaryKey =
          destinationSyncMode === "append_dedup"
            ? stream.sourceDefinedPrimaryKey || []
            : [];
      });
    }
  }
};
</script>
