<template>
  <div :class="['option', { selected }]" @click="$emit('select', option)">
    <div class="title">{{ option.label }}</div>
    <div class="desc">{{ option.description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    option: Object,
    selected: Boolean
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.option {
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
  width: 250px;
  .title {
    // font-size: @local-font-size-md;
    color: @local-title-color;
    margin-bottom: 4px;
  }
  .desc {
    color: @text-color-secondary;
    font-size: 12px;
  }
  &:hover {
    background: #f5f5f5;
  }
  &.selected {
    background: @blue-1;
  }
}
</style>
