import virtual from "vue-virtual-scroll-list";
import merge from "lodash/merge";

/**
 * overwrite virtual list, supports `scroll-element` prop
 */

const SLOT_TYPE = {
  HEADER: "thead",
  FOOTER: "tfoot"
};

let headerSizeNoSlot = 0; // 非插槽的头部大小
let headerSizeSlot = 0; // 插槽的头部大小

const overwrite = {
  props: {
    scrollElement: [HTMLElement, Node]
  },
  watch: {
    scrollElement() {
      this.addScrollListener();
    },
    pageMode() {
      this.addScrollListener();
    }
  },
  activated() {
    // set back offset when awake from keep-alive
    this.scrollToOffset(this.virtual.offset);

    this.addScrollListener();
  },
  deactivated() {
    this.removeScrollListener();
  },
  mounted() {
    // set position
    if (this.start) {
      this.scrollToIndex(this.start);
    } else if (this.offset) {
      this.scrollToOffset(this.offset);
    }

    // in page mode we bind scroll event to document
    if (this.pageMode) {
      this.updatePageModeFront();
    }
    this.addScrollListener();
  },
  beforeDestroy() {
    this.virtual.destroy();
    this.removeScrollListener();
  },
  methods: {
    // __lastScrollElement__ 记录上一次添加事件的元素，避免未及时移除监听器的漏网之鱼
    addScrollListener() {
      this.removeScrollListener();
      if (!this.pageMode) {
        return;
      }
      const element = this.scrollElement || document;
      const opts = { passive: false };
      element.addEventListener("scroll", this.onScroll, opts);
      this.__lastScrollElement__ = element;
    },
    // 全方位保障 document 及其它元素上的监听器移除...
    removeScrollListener() {
      const handle = el => {
        if (!el) {
          return;
        }
        el.removeEventListener("scroll", this.onScroll, { passive: false });
      };
      handle(document);
      handle(this.scrollElement);
      handle(this.__lastScrollElement__);
      this.__lastScrollElement__ = null;
    },
    getScrollElementValue(key) {
      return this.scrollElement
        ? this.scrollElement[key]
        : document.documentElement[key] || document.body[key];
    },
    setScrollElementValue(key, value) {
      if (this.scrollElement) {
        this.scrollElement[key] = value;
      } else {
        document.body[key] = value;
        document.documentElement[key] = value;
      }
    },
    // return current scroll offset
    getOffset() {
      if (this.pageMode) {
        return this.getScrollElementValue(this.directionKey);
      } else {
        const { root } = this.$refs;
        return root ? Math.ceil(root[this.directionKey]) : 0;
      }
    },
    // return client viewport size
    getClientSize() {
      const key = this.isHorizontal ? "clientWidth" : "clientHeight";
      if (this.pageMode) {
        return this.getScrollElementValue(key);
      } else {
        const { root } = this.$refs;
        return root ? Math.ceil(root[key]) : 0;
      }
    },
    // return all scroll size
    getScrollSize() {
      const key = this.isHorizontal ? "scrollWidth" : "scrollHeight";
      if (this.pageMode) {
        return this.getScrollElementValue(key);
      } else {
        const { root } = this.$refs;
        return root ? Math.ceil(root[key]) : 0;
      }
    },
    // set current scroll position to a expectant offset
    scrollToOffset(offset) {
      if (this.pageMode) {
        this.setScrollElementValue(this.directionKey, offset);
      } else {
        const { root } = this.$refs;
        if (root) {
          root[this.directionKey] = offset;
        }
      }
    },
    // when using page mode we need update slot header size manually
    // taking root offset relative to the browser as slot header size
    updatePageModeFront() {
      const { root } = this.$refs;
      if (root) {
        const rect = root.getBoundingClientRect();
        const { defaultView } = root.ownerDocument;
        let offsetFront = this.isHorizontal
          ? rect.left + defaultView.pageXOffset
          : rect.top + defaultView.pageYOffset;

        /**
         * 这里出现了问题，更新 slotHeaderSize 的时候，如果有头部插槽也有非插槽的头部元素，会互相覆盖掉（通常只会保存头部插槽的大小），导致滚动渲染错误
         * 添加下面这个代码进行问题修复
         */
        headerSizeNoSlot = offsetFront;
        if (this.$slots.header && this.pageMode) {
          offsetFront += headerSizeSlot;
        }

        this.virtual.updateParam("slotHeaderSize", offsetFront);
      }
    },
    // event called when slot mounted or size changed
    onSlotResized(type, size, hasInit) {
      if (type === SLOT_TYPE.HEADER) {
        /**
         * 这里出现了问题，更新 slotHeaderSize 的时候，如果有头部插槽也有非插槽的头部元素，会互相覆盖掉（通常只会保存头部插槽的大小），导致滚动渲染错误
         * 添加下面这个代码进行问题修复
         */
        headerSizeSlot = size;
        if (this.pageMode) {
          size += headerSizeNoSlot;
        }

        this.virtual.updateParam("slotHeaderSize", size);
      } else if (type === SLOT_TYPE.FOOTER) {
        this.virtual.updateParam("slotFooterSize", size);
      }

      if (hasInit) {
        this.virtual.handleSlotSizeChange();
      }
    }
  }
};

/**
 * 从 virtual.extendOptions 读取源码中的组件选项
 * 再用 overwrite 重写部分属性，如 props、watch、methods、mounted 等
 * 此外需注意清空 _Ctor，每个组件选项注册成 component 时，都会通过类似
 * Vue.component(name, componentOptions) 的方法生成一个组件构造函数
 * 这个构造函数就缓存在 options._Ctor 中。需要删除来自
 * virtual.extendOptions 的 _Ctor 以便生成新的构造函数
 */
export default merge({}, virtual.extendOptions, overwrite, { _Ctor: null });
