<template>
  <div class="status-bar">
    <span
      v-for="status in ['normal', 'running', 'succeeded', 'failed']"
      :key="status"
      class="unit"
    >
      <JobStatus :show-title="false" :status="status" />
      <span class="label">{{ status | localeStatus }}</span>
    </span>
  </div>
</template>

<script>
import JobStatus from "@/views/connections/components/job-status";
import { localeStatus } from "@/views/connections/components/job-status";

export default {
  components: { JobStatus },
  filters: { localeStatus }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.status-bar {
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  border-radius: 32px;
  padding: 10px;
  display: flex;
  align-items: center;
  z-index: 100;
  user-select: none;
  cursor: default;
  .unit {
    display: flex;
    align-items: center;
    margin: 0 14px;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .label {
    font-size: 13px;
    margin-left: 6px;
    color: @text-color-secondary;
  }
}
</style>
