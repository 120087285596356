<script>
import ImageEmpty from "@/assets/images/ai_add.svg";
import VirtualList from "@/components/virtual-list";
import Header from "./Header";
import Stream from "./Stream";

export default {
  props: {
    getScrollElement: Function,
    streams: Array,
    destinationModes: Array,
    streamNamePrefix: String
  },
  render() {
    const streams = this.streams || [];
    if (!streams.length) {
      const styles = { height: "220px", marginTop: "100px" };
      return <a-empty image={ImageEmpty} imageStyle={styles} />;
    }
    const isAllChecked = streams.every(item => item.config.selected);
    const extraProps = {
      destinationModes: this.destinationModes,
      streamNamePrefix: this.streamNamePrefix
    };
    return (
      <VirtualList
        page-mode
        // 每条记录大约 60px 高度
        keeps={Math.floor(screen.height / 60)}
        data-key={source => source.stream.name}
        data-sources={streams}
        data-component={Stream}
        extra-props={extraProps}
        scroll-element={this.getScrollElement()}
      >
        <Header
          slot="header"
          all-checked={isAllChecked}
          onCheck={this.onCheckAll}
        />
      </VirtualList>
    );
  },
  methods: {
    onCheckAll(selected = false) {
      for (const item of this.streams) {
        item.config.selected = selected;
      }
    }
  }
};
</script>
