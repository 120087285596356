<template>
  <div class="ndl-color-gray">
    <span>{{ createdAt }}</span>
  </div>
</template>

<script>
export default {
  props: {
    record: Object
  },
  computed: {
    createdAt() {
      const job = this.record.job || {};
      return job.createdAt;
    }
  }
};
</script>
