<template>
  <a-row :gutter="24">
    <a-col :span="8">
      <a-form-item :label="labels[0] || '同步频率'" required>
        <a-select v-model="frequencyType">
          <a-select-option :value="syncManual">
            {{ labels[3] || "手动同步" }}
          </a-select-option>
          <a-select-option
            v-for="(item, index) in presets"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
          <a-select-option :value="syncCustom">自定义</a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
    <a-col v-if="frequencyType === syncCustom" :span="8">
      <a-form-item :label="labels[1] || '自定义同步频率'" required>
        <a-input v-model="value.units">
          <a-select
            v-model="value.timeUnit"
            slot="addonAfter"
            style="width: 100px"
          >
            <a-select-option
              v-for="item in TimeUnits"
              :key="item"
              :value="item"
            >
              {{ item | localeTimeUnits }}
            </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
    </a-col>
    <a-col v-if="value" :span="8">
      <a-form-item :label="labels[2] || '开始时间'">
        <a-date-picker
          v-model="startTime"
          :locale="locale"
          show-time
          style="width: 100%"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { TimeUnits } from "@/views/connections/utils/sync-frequency";
import { localeTimeUnits } from "@/views/connections/utils/sync-frequency";
import { Schedule, presets } from "./frequency-presets";
import { matchSchedule, convertFrequencyType } from "./frequency-presets";

export default {
  props: {
    value: Object,
    /**
     * 定义控件显示的文本，配置消息推送任务时需要自定义 label
     * [
     *  第一个 form-item 的 label,
     *  第二个 form-item 的 label,
     *  第三个 form-item 的 label,
     *  手动同步选项的 label
     * ]
     */
    labels: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 此组件在 <a-modal /> 中使用时因无相关 locale-provider
      // 父级组件，文本会显示成英文，这里统一提供 locale 选项
      locale,
      presets,
      TimeUnits,
      frequencyType: matchSchedule(this.value)
    };
  },
  computed: {
    syncManual() {
      return Schedule.MANUAL;
    },
    syncCustom() {
      return Schedule.CUSTOM;
    },
    // 接口要求 startTime 保存为 timestamp
    startTime: {
      // 要求输入 moment 对象
      get() {
        const value = this.value && this.value.startTime;
        return value ? dayjs(value) : null;
      },
      // <date-picker /> 组件输出的是 moment 对象
      // 然而我们用 dayjs 替换掉 moment 了，所以是个 dayjs 对象
      set(moment) {
        const schedule = this.value || {};
        this.$set(schedule, "startTime", moment && Number(moment));
      }
    }
  },
  watch: {
    value(val) {
      this.frequencyType = matchSchedule(val);
    },
    frequencyType(type) {
      this.$emit("input", convertFrequencyType(type));
    }
  },
  filters: { localeTimeUnits }
};
</script>
