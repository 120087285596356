<script>
import SelectConnector from "@/views/connections/components/connector-selector";
import { Types } from "@/views/connections/models/connectors";

export default {
  props: {
    connection: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: null
    };
  },
  render() {
    return (
      <SelectConnector
        ref="select"
        category={Types.DESTINATION}
        value={this.id}
        onInput={val => (this.id = val)}
        onValidate={this.$listeners.validate}
      />
    );
  },
  created() {
    this.validate();
  },
  watch: {
    id() {
      this.validate();
    }
  },
  methods: {
    async next() {
      await this.$refs.select.submit();
      this.connection.destinationId = this.id;
    },
    async prev() {
      this.connection.sourceId = null;
    },
    validate() {
      this.$emit("validate", !!this.id);
    }
  }
};
</script>
