import Vue from "vue";
import * as API from "@/api/connections";

class Project {
  id = "";
  name = "";
  description = "";
  constructor(opts) {
    Object.assign(this, opts);
  }
  sync() {
    return API.updateTransform(this);
  }
  static from(data) {
    return new Project(data);
  }
}

class ProjectList {
  /**
   * @type {Project[]}
   */
  list = [];
  async sync() {
    const { data } = await API.getTransformList();
    const list = data.map(Project.from);
    list.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.list = list;
    return list;
  }
  /**
   * 新增项，id 已存在时不新增
   * @param {Project | { [prop?: string]: any }} data
   */
  push(data) {
    const prj = new Project(data);
    const dup = this.list.find(item => item.id == prj.id);
    if (!dup) {
      this.list.push(prj);
      return prj;
    }
    return dup;
  }
  /**
   * @param {string | Project} project
   */
  remove(project) {
    const id = typeof project === "object" ? project.id : project;
    this.list = this.list.filter(item => item.id != id);
    return API.deleteTransform(id);
  }
  /**
   * @param {string[] | Project[]} transforms
   * @returns {Project[]}
   */
  map(transforms) {
    const list = this.list;
    const mapped = [].concat(transforms).map(item => {
      const id = typeof item === "object" ? item.id : item;
      return list.find(item => item.id == id);
    });
    return Array.from(new Set(mapped)).filter(Boolean);
  }
}

const projectList = new ProjectList();

export default Vue.observable(projectList);
