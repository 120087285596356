<script>
import ConnectorDetails from "./components/connector-details";

export default {
  render() {
    const connectorId = this.$route.params.connectorId;
    return <ConnectorDetails connectorId={connectorId} />;
  }
};
</script>
