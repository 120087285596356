class Origin {
  constructor(source, opts) {
    this.x = 0;
    this.y = 0;
    // 计算原点位置时，从 (x, y) 偏移至 (x + offsetX, y + offsetY)
    this.offsetX = 0;
    this.offsetY = 0;
    // 当 source 为一个点时，extentX、extentY 都为 0
    // 当 source 为一个面时，extentX、extentY 为 宽高的一半
    // 鼠标点击时，MouseEvent 视为一个点，而一个 HTMLElement，则视为一个面
    this.extentX = 0;
    this.extentY = 0;
    if (source) this.from(source, opts);
  }

  from(source, opts) {
    if (source instanceof MouseEvent) {
      this.fromEvent(source, opts);
    } else if (source instanceof Element) {
      this.fromElement(source, opts);
    }
  }

  fromElement(elm, opts = {}) {
    const { left, top, width, height } = elm.getBoundingClientRect();
    const inverse =
      typeof opts === "boolean"
        ? { x: opts, y: opts }
        : Object.assign({ x: false, y: false }, opts);
    const extentX = width / 2;
    const extentY = height / 2;
    this.x = left + extentX;
    this.y = top + extentY;
    this.extentX = extentX * (inverse.x ? -1 : 1);
    this.extentY = extentY * (inverse.y ? -1 : 1);
    this.offsetX = this.extentX;
    this.offsetY = this.extentY;
  }

  fromEvent(evt) {
    this.x = evt.clientX;
    this.y = evt.clientY;
    this.extentX = 0;
    this.extentY = 0;
    this.offsetX = 0;
    this.offsetX = 0;
  }

  setOffsetX(x) {
    this.offsetX = x;
  }

  setOffsetY(y) {
    this.offsetY = y;
  }

  static from(source, opts) {
    return new Origin(source, opts);
  }
}

export default Origin;
