<template>
  <div class="card">
    <CardContent :item="item" />
    <div class="line"></div>
    <CardOper :item="item" />
    <CardSync :item="item" />
  </div>
</template>

<script>
import CardContent from "./CardContent";
import CardOper from "./CardOper";
import CardSync from "./CardSync";

export default {
  components: { CardContent, CardOper, CardSync },
  props: {
    item: Object
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.card {
  display: inline-block;
  width: @local-cart-width;
  margin: @local-cart-margin;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  position: relative;
  background-color: #fff;

  &:hover {
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
  }
  .line {
    height: 1px;
    background-color: #f2f3f5;
  }
}
</style>
