<template>
  <div class="sync-con" @click.stop>
    <a-tooltip v-if="stopping" title="正在取消">
      <a-icon class="sync canceling" type="sync" spin />
    </a-tooltip>

    <a-tooltip v-else-if="item.isSyncing" title="同步中，点击取消同步">
      <div class="running" @click="onStop">
        <a-icon class="sync syncing" type="sync" spin />
        <a-icon class="sync cancel" type="minus-circle" />
      </div>
    </a-tooltip>

    <a-tooltip v-else-if="running" title="正在同步">
      <a-icon class="sync syncing" type="sync" spin />
    </a-tooltip>

    <a-tooltip v-else title="立即同步">
      <a-icon class="sync" type="sync" :spin="false" @click="onSync" />
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  data() {
    return {
      stopping: false,
      running: false
    };
  },
  watch: {
    // 根据连接的 isSyncing 状态判断是否正在同步/已取消同步
    "item.isSyncing"() {
      this.stopping = false;
      this.running = false;
    }
  },
  methods: {
    onStop() {
      this.stopping = true;
      const onFailed = () => {
        this.stopping = false;
      };
      this.$parent.$emit("stop", this.item, onFailed);
    },
    onSync() {
      this.running = true;
      const onFailed = () => {
        this.running = false;
      };
      this.$parent.$emit("sync", this.item, onFailed);
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.sync-con {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  .sync {
    background-color: @blue-5;
    color: #ffffff;
    padding: 4px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
  }
  .cancel {
    display: none;
    background-color: @orange-6;
  }
  .canceling {
    cursor: not-allowed;
    color: @red-6;
    background-color: @red-1;
  }
  .syncing {
    background-color: #ddd;
    cursor: not-allowed;
  }
  .running {
    &:hover .syncing {
      display: none;
    }
    &:hover .cancel {
      display: inline-block;
    }
  }
}
</style>
