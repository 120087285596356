<template>
  <div :class="['wrapper', { expanded: showAll }]">
    <ConnectorCard
      v-for="item in connectors"
      :key="item.id"
      :connector-id="item.id"
      :class="{ checked: item.id == value }"
      @click.native="$emit('input', item.id, item)"
    >
      <Checkbox checked />
    </ConnectorCard>
    <div v-if="!showAll" class="expand-rel">
      <a @click="expanded = true">
        显示全部
        <i class="ndl-icon-chevrons-down"></i>
      </a>
    </div>
  </div>
</template>

<script>
import ConnectorCard from "@/views/connections/components/connector-card";
import { connectorList, Types } from "@/views/connections/models/connectors";
import Checkbox from "@/components/checkbox";

export default {
  components: { ConnectorCard, Checkbox },
  props: {
    category: String,
    expandAll: Boolean,
    // connector id
    value: [String, Number]
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    connectors() {
      switch (this.category) {
        case Types.SOURCE:
          return connectorList.sources;
        case Types.DESTINATION:
          return connectorList.destinations;
        default:
          return [];
      }
    },
    showAll() {
      if (this.expandAll) {
        return true;
      }
      return this.connectors.length > 3 ? this.expanded : true;
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

@cell-width: 280px;
@cell-height: 70px;
@gutter: 16px;
.wrapper {
  // 每行 3 个单元格
  width: @cell-width * 3 + @gutter * 6;
  // 默认只展示第一行，点击展开按钮显示全部
  height: @cell-height + @gutter * 2 + 40px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  .expand-rel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    text-align: center;
    background: linear-gradient(0, #fff, fade(#fff, 45%));
  }
  &.expanded {
    height: auto;
  }
}
.card {
  width: @cell-width;
  height: @cell-height;
  padding: 0 12px;
  flex-shrink: 0;
  border: solid 1px @ndl-border-color;
  border-radius: 8px;
  margin: @gutter;
  position: relative;
  cursor: pointer;
  &:hover,
  &.checked {
    border-color: @blue-6;
  }
  /deep/ img {
    height: 35px;
    width: 35px;
  }
  /deep/ .title {
    font-size: 14px;
  }
  .checkbox {
    position: absolute;
    top: 12px;
    right: 12px;
    visibility: hidden;
    box-shadow: 0 0 24pt @blue-5;
  }
  &.checked {
    box-shadow: inset 0 0 0 100pt @blue-1;
  }
  &.checked .checkbox {
    visibility: visible;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    // 小屏幕每行 2 个单元格
    width: @cell-width * 2 + @gutter * 4;
  }
}
</style>
