import Vue from "vue";
import router from "@/router";
import * as API from "@/api/connections";
import * as helper from "./helper";
import { definitionList, Types, localeTypes } from "./definitions";
import PollingProxy from "./polling";

function isConnectionRoute(path = "") {
  return path.startsWith("/connections");
}

class ConnectorList {
  constructor() {
    this.connectors = [];
    this.connections = [];
    this.connectionStaus = [];
    this.dragging = false; // 首页 连接器\连接 是否拖拽调整位置中
    this.loading = false;
    this.init();
    this.polling = new PollingProxy();
    this.polling.push(this.reloadConnectionStatus.bind(this), {
      leading: true,
      interval: 2000
    });
    this.polling.push(this.reloadConnectors.bind(this), {
      leading: false,
      interval: 5000
    });
    this.polling.push(this.reloadConnections.bind(this), {
      leading: false,
      interval: 5000
    });
    // 此时 router 还未初始化，不能，currentRoute.path 为 "/"
    // 因此只能用 location.pathname
    if (!isConnectionRoute(location.pathname)) {
      this.polling.stop();
    }
  }
  get sources() {
    return this.connectors.filter(item => item.category === Types.SOURCE);
  }
  get destinations() {
    return this.connectors.filter(item => item.category === Types.DESTINATION);
  }
  // 主数据库
  get mainDestination() {
    let mainDestination = null;
    this.destinations.forEach(destination => {
      if (destination.isMainDestination) {
        mainDestination = destination;
      }
    });
    return mainDestination || {};
  }

  /**
   * 初始化 definitions 列表，一般只会在进入应用时初始化一次
   * 随后需要更新数据可调用 reloadConnectors() / reloadConnections() 方法
   * @returns {Promise<any>}
   */
  init = helper.keepState(function() {
    this.loading = true;
    return Promise.all([
      this.reloadConnectors(),
      this.reloadConnections()
    ]).finally(() => {
      this.loading = false;
    });
  }, true);

  reloadConnectors = helper.keepState(async function() {
    const { data } = await API.getConnectors();
    helper.normalizeConnectors(data);
    this.connectors = data;
  });
  reloadConnections = helper.keepState(async function() {
    const { data } = await API.getConnectionList();
    helper.normalizeConnections(data, this.connections);
    this.connections = data;
    helper.updateConnectionStatus(this.connections, this.connectionStaus);
  });
  reloadConnectionStatus = helper.keepState(async function() {
    const { data } = await API.getConnectionStatus();
    helper.updateConnectionStatus(this.connections, data);
    this.connectionStaus = data;
  });

  /**
   * 根据连接器 ID 获取连接器信息
   * @param {Number | String} connectorId 连接器 ID
   * @returns {Object} 连接器
   */
  getConnector(connectorId) {
    return this.connectors.find(item => item.id == connectorId);
  }

  /**
   * 根据连接 ID 获取连接信息
   * @param {Number | String} connectionId 连接 ID
   * @returns {Object} 连接
   */
  getConnection(connectionId) {
    return this.connections.find(item => item.id == connectionId);
  }

  /**
   * 根据连接器 ID 获取与之相关的连接列表
   * @param {Number | String} connectorId 连接器 ID
   * @returns {Array} 连接列表
   */
  getConnectionsOfConnector(connectorId) {
    return this.connections.filter(item => {
      return item.sourceId == connectorId || item.destinationId == connectorId;
    });
  }

  /**
   * 根据连接器 ID 获取与之相关的 某一个状态的 连接列表
   * @param {Number | String} connectorId 连接器 ID
   * @param {String} connectorId 连接状态
   * @returns {Array} 连接列表
   */
  getConnectionsOfConnectorStatus(connectorId, status) {
    return this.connections.filter(item => {
      return (
        (item.sourceId == connectorId || item.destinationId == connectorId) &&
        (!status || status === item.latestSyncJobStatus)
      );
    });
  }

  /**
   * 根据连接器 ID 获取连接器名称
   * @param {Number | String} connectorId 连接器 ID
   * @returns {String} 连接器名称
   */
  getConnectorName(connectorId) {
    const connector = this.getConnector(connectorId);
    return connector ? connector.name : null;
  }

  getConnectorIcon(connectorId) {
    const connector = this.getConnector(connectorId) || {};
    // 部分连接器如「automator-source」支持自定义 icon
    const connectionConfiguration = connector.connectionConfiguration;
    if (connectionConfiguration && connectionConfiguration.customIcon) {
      return connectionConfiguration.customIcon;
    }
    return definitionList.getIcon(connector.definitionId);
  }

  getConnectorCategory(connectorId) {
    const connector = this.getConnector(connectorId) || {};
    return connector.category || null;
  }

  getDefinitionName(connectorId) {
    const connector = this.getConnector(connectorId);
    return definitionList.getName(connector && connector.definitionId);
  }

  getDefinitionId(connectorId) {
    const connector = this.getConnector(connectorId) || {};
    return connector.definitionId || null;
  }

  /**
   * 修改 connector 和 connection 状态
   * 其中 connector 包含与传入 connectorId 有连接关系的所有 connectors
   * @param {String} connectorId connectorId 为 null 时，所有 connector 都将设为 active 状态
   */
  activeConnector(connectorId = null) {
    const { connectors, connections } = this;
    const FALSY_STATUS = !connectorId;
    // 首先重置所有 connector 状态
    for (const item of connectors) {
      item.isActive = FALSY_STATUS;
    }
    const relatedConnectors = new Set();
    const relatedConnections = new Set();
    // 再根据 connections 找出与目标 connector 相关联的 connector 和 connection
    for (const item of connections) {
      const { sourceId, destinationId } = item;
      if (sourceId == connectorId || destinationId == connectorId) {
        relatedConnectors.add(sourceId);
        relatedConnectors.add(destinationId);
        relatedConnections.add(item.id);
      }
      // 重置所有 connection 状态
      item.isActive = FALSY_STATUS;
    }
    relatedConnectors.add(connectorId);
    // 激活相关的 connector
    const connectorMap = connectors.reduce((map, item) => {
      map[item.id] = item;
      return map;
    }, {});
    for (const id of relatedConnectors) {
      const connector = connectorMap[id] || {};
      connector.isActive = true;
    }
    // 激活相关的 connection
    const connectionMap = connections.reduce((map, item) => {
      map[item.id] = item;
      return map;
    }, {});
    for (const id of relatedConnections) {
      const connection = connectionMap[id] || {};
      connection.isActive = true;
    }
  }

  /**
   * 设置 dragging
   * @param {Boolean} dragging
   */
  setDragging(dragging = false) {
    this.dragging = dragging;
    dragging ? this.polling.stop() : this.polling.active();
  }

  async saveConnector(connectorConfig, isTemplate = false) {
    const saveConnector = isTemplate
      ? API.saveTemplateConnector
      : API.saveConnector;
    const { data } = await saveConnector(connectorConfig);
    if (!connectorConfig.id) {
      this.connectors = this.connectors.concat(data);
    }
    return data;
  }
  async saveConnection(connectionConfig) {
    const { data } = await API.saveConnection(connectionConfig);
    await this.reloadConnections();
    return data;
  }
  async deleteConnector(connectorId) {
    await API.deleteConnector(connectorId);
    this.connectors = this.connectors.filter(item => item.id !== connectorId);
  }
  async deleteConnection(connectionId) {
    await API.deleteConnection(connectionId);
    this.connections = this.connections.filter(
      item => item.id !== connectionId
    );
  }
  async enableConnection(connectionId) {
    const conn = this.getConnection(connectionId) || {};
    conn.isSwitching = true;
    await API.enableConnection(connectionId);
    conn.status = "active";
    conn.isSwitching = false;
  }
  async disableConnection(connectionId) {
    const conn = this.getConnection(connectionId);
    conn.isSwitching = true;
    await API.disableConnection(connectionId);
    conn.status = "inactive";
    conn.isSwitching = false;
  }
  async sortConnector(connectors) {
    const olbConnectors = this.connectors;
    try {
      if (!connectors || !connectors.length) return;
      // 计算排序
      const ids = connectors.map(connector => connector.id);
      const otherMaps = [];
      this.connectors.forEach((connector, index) => {
        if (ids.indexOf(connector.id) === -1) {
          otherMaps.push({ connector, index });
        }
      });
      otherMaps.forEach(item => {
        const { connector, index } = item;
        connectors.splice(index, 0, connector);
      });
      this.connectors = connectors;
      // 调用接口
      await API.sortConnector(
        connectors.map((connector, index) => ({
          connectorId: connector.id,
          orderNo: index + 1
        }))
      );
    } catch {
      this.connectors = olbConnectors;
    }
  }
  async sortConnection(connections) {
    const oldConnections = this.connections;
    try {
      if (!connections || !connections.length) return;
      // 计算排序
      const ids = connections.map(connection => connection.id);
      const otherConnections = this.connections.filter(
        connection => ids.indexOf(connection.id) === -1
      );
      this.connections = connections.concat(otherConnections);
      // 调用接口
      await API.sortConnection(
        this.connections.map((connection, index) => ({
          connectionId: connection.id,
          orderNo: index + 1
        }))
      );
    } catch {
      this.connections = oldConnections;
    }
  }
}

export { Types, localeTypes };

export const connectorList = Vue.observable(new ConnectorList());

export default connectorList;

router.beforeEach((to, from, next) => {
  if (isConnectionRoute(to.path)) {
    connectorList.polling.active();
  } else {
    connectorList.polling.stop();
  }
  next();
});
