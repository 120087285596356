<template>
  <!-- data-connector-id 属性用于生成 guideline -->
  <ConnectorCard
    class="group-item"
    :data-connector-id="connectorId"
    :connector-id="connectorId"
    :show-category="false"
    @click.native="$emit('select')"
    @mouseenter.native="onMouseEnter"
    @mouseleave.native="onMouseLeave"
  />
</template>

<script>
import debounce from "lodash/debounce";
import ConnectorCard from "@/views/connections/components/connector-card";

export default {
  components: { ConnectorCard },
  props: {
    connectorId: [Number, String]
  },
  methods: {
    /**
     * 尽量避免给方法 debounce，因为所有实例共用方法可能会导致
     * 某些方法调用不执行，很难 debug。但此处因为所有 <GroupItem />
     * 同一时刻只能 active 一个实例，因此此处 debounce 不会导致异常
     */
    notify: debounce(function notify(connectorId) {
      this.$emit("active", connectorId);
    }, 100),
    onMouseEnter() {
      this.notify(this.connectorId);
    },
    onMouseLeave() {
      this.notify(null);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

// 间距
@gutter: 20px;
@icon-size: 45px;

.group-item {
  height: 100px;
  border: solid 1px #ebebeb;
  border-radius: 16px;
  padding: 16px 24px;
  margin-bottom: @gutter;
  cursor: pointer;
  &:hover {
    background: #fafafa;
  }
  /deep/ img {
    width: @icon-size;
    height: @icon-size;
  }
  /deep/ .content {
    padding: 0;
    margin-left: 20px;
  }
  /deep/ .title {
    white-space: normal;
  }
}
</style>
