<script>
import ConnectionList from "@/views/connections/components/connection-list";
import { connectorList } from "@/views/connections/models/connectors";
import sessions from "@/views/connections/utils/sessions";
import * as helpers from "@/views/connections/utils/connector-category";
import * as APIJob from "@/api/connections";

export default {
  inject: ["tabsVm"],
  props: {
    connector: {
      required: true,
      type: Object
    }
  },
  computed: {
    category() {
      return this.connector.category;
    }
  },
  render() {
    const connector = this.connector;
    const connections = connectorList.getConnectionsOfConnector(connector.id);
    return (
      <ConnectionList
        category={connector.category}
        connections={connections}
        onDelete={this.onDelete}
        onHistory={this.onHistory}
        onVisit={this.onVisit}
        onCreate={this.onCreate}
        onSetting={this.onSetting}
        onSelect={this.onSetting}
        onSync={this.onSync}
        onStop={this.onStop}
        onEnable={this.onEnable}
        onDisable={this.onDisable}
      />
    );
  },
  methods: {
    onDelete(connection) {
      const label = helpers.localeOppositeTypes(this.category);
      const idProp = helpers.connectorIdPropOfOppositeType(this.category);
      const connectorName = connectorList.getConnectorName(connection[idProp]);
      this.$confirm({
        title: "确认删除",
        content: `确定要删除与${label}「${connectorName}」的连接吗？`,
        okType: "danger",
        onOk: () => connectorList.deleteConnection(connection.id)
      });
    },
    onHistory(connection) {
      this.tabsVm.showHistory();
      // 在 session 中记录选中的 connection，便于切换后定位到该连接
      // 也可以选择借助 $route.params 传递数据，这里还是倾向于 sessionStorage
      sessions.connectionId(connection.id);
    },
    onVisit(connection) {
      const idProp = helpers.connectorIdPropOfOppositeType(this.category);
      this.$router.push({
        name: "connector-details",
        params: { connectorId: connection[idProp] }
      });
    },
    onCreate() {
      // 在 session 中存储当前 connector id
      // 便于跳转后直接选中该连接器作为数据源 / 目标库
      const idProp = helpers.connectorIdPropOfType(this.category);
      sessions[idProp](this.connector.id);
      sessions.fromConnector(this.connector.id);
      this.$router.push({ name: "connection-creation" });
    },
    onSetting(connection) {
      this.$router.push({
        name: "connection-editor",
        params: { connectionId: connection.id }
      });
    },
    async onSync(connection, onFailed) {
      try {
        await APIJob.runJob(connection.id);
      } catch {
        onFailed();
        this.$message.error("同步失败");
      }
    },
    async onStop(connection, onFailed) {
      try {
        await APIJob.stopJob(connection.id);
      } catch {
        onFailed();
        this.$message.error("取消失败");
      }
    },
    onEnable(connection) {
      connectorList.enableConnection(connection.id);
    },
    onDisable(connection) {
      connectorList.disableConnection(connection.id);
    }
  }
};
</script>
