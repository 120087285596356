<template>
  <a-modal :width="1000" :footer="null" :centered="true" :mask-closable="false">
    <ModelHeader slot="title" :img-src="connectorIcon" :title="connectorName" />
    <div class="modal-body">
      <History
        :category="connector.category"
        :connections="connections"
        :hide-connections="false"
      />
    </div>
  </a-modal>
</template>

<script>
import ModelHeader from "@/views/connections/components/modal-common-header";
import History from "@/views/connections/components/connection-history";
import sessions from "@/views/connections/utils/sessions";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  components: { ModelHeader, History },
  props: {
    connector: {
      type: Object,
      required: true
    },
    connections: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      confirmLoading: false
    };
  },
  created() {
    sessions.connectionId(this.connections[0].id);
  },
  computed: {
    connectorIcon() {
      return connectorList.getConnectorIcon(this.connector.id);
    },
    connectorName() {
      return connectorList.getConnectorName(this.connector.id) + " - 同步历史";
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.modal-body {
  padding: 0px 20px 10px;
}
.modal-body /deep/ .wrapper:nth-child(1) {
  border: none;
  min-height: 550px;
  padding-bottom: 50px;
  border-radius: 0;
}
.modal-body /deep/ .side {
  display: none;
}
.modal-body /deep/ .wrapper .content .header {
  height: 40px;
  .title {
    width: 0;
    overflow: hidden;
  }
}
</style>
