<template>
  <ConnectorCard :connector-id="connectorId" :show-category="false">
    <div class="append">
      <a-button type="danger" @click="onDelete()">删除</a-button>
    </div>
  </ConnectorCard>
</template>

<script>
import ConnectorCard from "@/views/connections/components/connector-card";
import connectorList from "@/views/connections/models/connectors";

export default {
  components: { ConnectorCard },
  props: {
    connector: Object
  },
  computed: {
    connectorId() {
      return this.connector ? this.connector.id : null;
    }
  },
  methods: {
    onDelete() {
      this.$confirm({
        title: "确认删除",
        content: `确定要删除连接器「${this.connector.name}」吗？`,
        okType: "danger",
        onOk: async () => {
          await connectorList.deleteConnector(this.connectorId);
          this.$router.replace({ name: "connections-overview" });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.card {
  display: flex;
  align-items: center;
  margin: 40px 0;
  border: solid 1px #ebebeb;
  border-radius: 8px;
  padding: 28px;
  /deep/ img {
    height: 55px;
    width: 55px;
    margin-right: 20px;
  }
  /deep/ .title {
    font-size: @local-font-size-lg;
  }
  .append {
    margin-left: 20px;
    flex-shrink: 0;
  }
}
</style>
