<template>
  <Row>
    <Cell type="info"> 已连接{{ categoryLabel }}（{{ count }}） </Cell>
    <Cell type="frequence">同步频率</Cell>
    <Cell type="status"></Cell>
    <Cell type="latest">最近同步</Cell>
    <Cell type="switch"></Cell>
    <Cell type="actions">
      <a-button type="primary" @click="$parent.$emit('create')">
        添加连接
      </a-button>
    </Cell>
  </Row>
</template>

<script>
import Row from "./Row";
import Cell from "./Cell";

export default {
  components: { Row, Cell },
  props: {
    categoryLabel: String,
    count: Number
  }
};
</script>
