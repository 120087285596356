<template>
  <a-steps progress-dot :current="current">
    <a-step>
      <template v-if="current <= 0" slot="title">1、选择数据源</template>
      <ConnectorCard v-else slot="title" :connector-id="sourceId" />
    </a-step>
    <a-step>
      <template v-if="current <= 1" slot="title">2、选择目标库</template>
      <ConnectorCard v-else slot="title" :connector-id="destinationId" />
    </a-step>
    <a-step title="3、配置连接" />
  </a-steps>
</template>

<script>
import ConnectorCard from "@/views/connections/components/connector-card";

export default {
  components: { ConnectorCard },
  props: {
    sourceId: [String, Number],
    destinationId: [String, Number],
    value: [String, Number]
  },
  computed: {
    current() {
      if (!this.sourceId) {
        return 0;
      } else if (!this.destinationId) {
        return 1;
      } else {
        return 2;
      }
    }
  },
  created() {
    this.$emit("input", this.current);
  },
  watch: {
    current(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="less" scoped>
.ant-steps {
  width: 80%;
  margin: 0 auto;
}
.card {
  width: 280px;
  text-align: left;
  /deep/ img {
    width: 36px;
    height: 36px;
  }
}
</style>
