<template>
  <section>
    <DefinitionDirectory
      :category="category"
      @back="$router.replace({ name: 'connections-overview' })"
      @change="onChange"
    />
    <div class="wrapper">
      <DefinitionSearch @search="keyword => (search = keyword)">
        全部{{ category | localeTypes }}连接器（{{ count }}）
      </DefinitionSearch>
      <DefinitionList
        :category="category"
        :search="search"
        @select="onSelect"
      />
    </div>
  </section>
</template>

<script>
import DefinitionDirectory from "./components/definition-directory";
import DefinitionSearch from "./components/definition-search";
import DefinitionList from "./components/definition-list";
import { definitionList, localeTypes } from "./models/definitions";

export default {
  components: { DefinitionDirectory, DefinitionSearch, DefinitionList },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    category() {
      return this.$route.params.category;
    },
    count() {
      return definitionList.count(this.category);
    }
  },
  filters: { localeTypes },
  methods: {
    async onChange(category) {
      this.$router.replace({
        name: "definition-directory",
        params: { category }
      });
      await this.$nextTick();
      this.$el.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    onSelect(def) {
      this.$router.push({
        name: "connector-creation",
        params: { definitionId: def.id }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "./assets/vars.less";

@gutter: 40px;
section {
  padding: @gutter;
  height: 100%;
  overflow: auto;
  background: #f8f8f8;
  display: flex;
  align-items: flex-start;
  .directory {
    flex-shrink: 0;
    width: 200px;
    position: sticky;
    top: 0;
  }
  .wrapper {
    flex: 1 1 0;
    background: #fff;
    border-radius: 8px;
    margin-left: @gutter;
    padding: 24px;
    min-height: 720px;
    max-width: 1300px;
  }
}
</style>
