<template>
  <!-- 已选 definition 时，填写连接器表单 -->
  <div v-if="definitionId" class="wrapper">
    <div class="header">
      <div class="left">
        <a-button type="link" icon="arrow-left" @click="onReset()">
          重新选择
        </a-button>
      </div>
      <div class="center">
        <img :src="definitionIcon" />
        <span class="title">{{ definitionName }}</span>
      </div>
      <div class="right"></div>
    </div>
    <ConnectorEditor
      v-model="connector"
      ref="editor"
      :definition-id="definitionId"
    />
  </div>
  <!-- 否则显示 definition 列表 -->
  <div v-else class="wrapper">
    <a-input-search v-model="search" placeholder="搜索连接器..." />
    <DefinitionList :category="category" :search="search" @select="onSelect" />
  </div>
</template>

<script>
import DefinitionList from "@/views/connections/components/definition-list";
import ConnectorEditor from "@/views/connections/components/connector-editor";
import definitionList from "@/views/connections/models/definitions";
import connectorList from "@/views/connections/models/connectors";

export default {
  components: { DefinitionList, ConnectorEditor },
  props: {
    category: String,
    // connector id
    value: [String, Number]
  },
  data() {
    return {
      search: null,
      definitionId: null,
      connector: null
    };
  },
  watch: {
    // 组件外部修改了 connectorId 时清空 definitionId，以便回到 definition list 界面
    value(val) {
      if (val) {
        this.definitionId = null;
        this.connector = null;
      }
    },
    connector(value) {
      this.$emit("update", value);
    }
  },
  computed: {
    definitionIcon() {
      return definitionList.getIcon(this.definitionId);
    },
    definitionName() {
      return definitionList.getName(this.definitionId);
    }
  },
  methods: {
    onSelect(definition) {
      this.definitionId = definition.id;
      this.connector = null;
      // 添加连接器时首先请客已选 connector id
      this.$emit("input", null);
      this.$el.scrollIntoView();
    },
    onReset() {
      this.definitionId = null;
      this.connector = null;
    },
    async create() {
      const editor = this.$refs.editor;
      if (!(editor && editor.validate())) {
        return;
      }
      const data = await connectorList.saveConnector({
        ...this.connector,
        category: this.category,
        definitionId: this.definitionId
      });
      this.$emit("input", data.id);
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.wrapper {
  border-radius: 12px;
  border: solid 1px @ndl-border-color;
  margin: 60px auto 0;
  max-width: 920px;
  min-height: 680px;
  .ant-input-search {
    display: block;
    width: 220px;
    margin: 40px auto;
  }
}
.header {
  display: flex;
  align-items: center;
  padding: 14px 18px;
  .left,
  .right {
    width: 50px;
  }
  .center {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 30px;
    max-width: 60px;
    margin: 0 14px;
  }
  .title {
    color: @local-title-color;
  }
}
</style>
