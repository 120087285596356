/**
 * 字符串搜索
 * @param {String} source 源字符串
 * @param {String} keyword 搜索关键词
 * @returns {Boolean} 是否包含关键词
 */
export function includes(source, keyword) {
  source = String(source || "").toLocaleLowerCase();
  keyword = String(keyword || "").toLocaleLowerCase();
  return source.includes(keyword);
}

/**
 * 合并两个 streams
 * @param {Array} streams 数据源 streams
 * @param {Array} sourceStreams 将要合并的 streams，例如已保存的 connection 中的 streams
 * @returns {Array} 合并后的数据源 streams
 */
export function mergeStreams(streams, sourceStreams) {
  streams = streams || [];
  sourceStreams = sourceStreams || [];
  const sourceMap = new Map(
    sourceStreams.map(item => [item.stream.name, item.config])
  );
  for (const item of streams) {
    Object.assign(item.config, sourceMap.get(item.stream.name));
  }
  return streams;
}
