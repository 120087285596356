<template>
  <div class="card">
    <img :src="connectorIcon || '/images/default_connector_icon.svg'" />
    <div class="content">
      <span class="title" :title="connectorName">{{ connectorName }}</span>
      <span class="desc">{{ connectorDesc }}</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { localeTypes } from "@/views/connections/utils/connector-category";
import { connectorList } from "../../../models/connectors";

export default {
  props: {
    connectorId: [Number, String],
    showCategory: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    connectorIcon() {
      return connectorList.getConnectorIcon(this.connectorId);
    },
    connectorName() {
      return connectorList.getConnectorName(this.connectorId);
    },
    connectorDesc() {
      const name = connectorList.getDefinitionName(this.connectorId);
      if (!this.showCategory) {
        return name;
      }
      const category = connectorList.getConnectorCategory(this.connectorId);
      return `${localeTypes(category)} - ${name}`;
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  display: flex;
  align-items: center;

  img {
    flex-shrink: 0;
  }
  .content {
    width: 0;
    padding: 0 12px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }
  .title {
    color: #232d54;
    font-size: 16px;
  }
  .desc {
    color: rgba(0, 0, 0, 0.45);
    font-size: 13px;
  }
  .title,
  .desc {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
