<template>
  <div :class="['cell', type]" v-on="$listeners">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: String
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.cell {
  width: 0;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.info {
    flex-grow: 2.5;
  }
  &.frequence,
  &.latest {
    color: @text-color-secondary;
  }
  &.status {
    width: 50px;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
  }
  &.switch {
    width: 80px;
    flex: 0 0 auto;
  }
  &.actions {
    min-width: 180px;
    padding: 0;
    justify-content: flex-end;
    font-size: 16px;
    color: @text-color-secondary;
  }
}
</style>
