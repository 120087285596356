import Vue from "vue";
import Contextify from "./src/Contextify";

/**
 * 设置/获取 component id，便于 contextify 识别是否为同一组件
 * @param {import("vue/types/umd").Component} component
 * @param {any} value component id
 * @returns {any}
 */
export function componentId(component, value) {
  const key = Symbol.for("component id for contextify");
  component = component || {};
  if (arguments.length > 1) {
    component[key] = value;
  } else {
    return component[key];
  }
}

/**
 * 为了更好的交互体验，连续两次调用传入相同的 source 和 component 时，
 * 第一次调用是打开 context，第二次调用就应该是关闭 context 不再继续弹出。
 *
 * 为实现这个需求，这里在每次 context mounted 时记录最后一个 context 的必要信息，
 * 在 context destroyed 时移除记录的内容。
 */
const prevState = {
  source: null,
  vm: null,
  component: null,
  ignore(source, component) {
    const isSameSource = source === prevState.source;
    const isSameComponent =
      component === prevState.component ||
      componentId(component) === componentId(prevState.component);
    return isSameSource && isSameComponent;
  }
};

/**
 * 在 context 中渲染组件
 * @typedef {{
 *    source: MouseEvent | Element,
 *    inverse: boolean | { x: boolean, y: boolean },
 *    container: HTMLElement,
 *    style: string | StyleSheet,
 *    ...props
 * }} ContextOptions
 * @typedef {{
 *    wait(): Promise<any>, // 等待 context 关闭
 * } & import("vue/types/umd").Component
 * } Context
 *
 * @param {string | import("vue/types/umd").Component} ContentComponent 在 context 中渲染的组件
 * @param {ContextOptions} opts context 个性化选项
 * @param {MouseEvent | Element} opts.source context 原点，通常是鼠标点击后的 mouse event
 * @param {Object | Boolean} opts.inverse context 边缘对齐方式
 * @param {HTMLElement} opts.container 挂载节点
 * @returns {Context}
 */
export default function(ContentComponent = {}, opts = {}) {
  if (prevState.ignore(opts.source, ContentComponent)) {
    return prevState.vm;
  }
  const Ctor = Vue.extend(Contextify);
  if (!ContentComponent.render && !ContentComponent.mixins) {
    ContentComponent.render = () => null;
  }
  const vm = new Ctor({
    components: { ContentComponent },
    mounted() {
      prevState.vm = this;
      prevState.component = ContentComponent;
      prevState.source = opts.source;
    },
    /**
     * destroyed hook 会在下一个 context mounted 之后才调用，
     * 因此需要判断一下 destroyed 的时候是否有必要重置 prevState
     */
    destroyed() {
      if (prevState.vm === this) {
        prevState.vm = null;
        prevState.component = null;
        prevState.source = null;
      }
    }
  });
  vm.init(opts);
  vm.$mount();
  /**
   * ⚠️ 注意重设根实例的 constructor
   * https://github.com/vueComponent/ant-design-vue/blob/3761eddb4b67e5ec7bf53854346959bb62203d33/components/_util/ContainerRender.jsx#L56
   * antd 调用了根组件的 constructor 来实例化子组件
   * 通常情况下，$root.constructor === Vue，这时候不会导致任何异常情况
   * 而当我们的根组件是 new Vue.extend(...) 构造出来的（例如本函数）
   * $root.constructor !== Vue，这时候会导致根组件的 hooks 重复调用、data 重复初始化等问题
   */
  vm.constructor = Vue;
  return vm;
}
