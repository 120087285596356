function access(key, value) {
  if (value !== undefined) {
    return sessionStorage.setItem(key, value);
  } else {
    const result = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);
    return result || null;
  }
}

const sessions = {
  connectionId(value) {
    return access("connectionId", value);
  },
  connectorId(value) {
    return access("connectorId", value);
  },
  sourceId(value) {
    return access("sourceId", value);
  },
  destinationId(value) {
    return access("destinationId", value);
  },
  fromConnector(value) {
    return access("fromConnector", value);
  }
};

export default sessions;
