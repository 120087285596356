<template>
  <section>
    <DefinitionSearch
      show-back
      @search="keyword => (search = keyword)"
      @back="onBack"
    >
      全部{{ category | localeTypes }}连接器（{{ count }}）
    </DefinitionSearch>
    <DefinitionList :category="category" :search="search" @select="onSelect" />
  </section>
</template>

<script>
import DefinitionSearch from "./components/definition-search";
import DefinitionList from "./components/definition-list";
import connectorAuthorize from "@/views/connections/components/modal-connector-authorize";
import { definitionList, localeTypes, Types } from "./models/definitions";

export default {
  components: { DefinitionSearch, DefinitionList },
  data() {
    return {
      category: Types.SOURCE,
      search: ""
    };
  },
  computed: {
    count() {
      return definitionList.count(this.category);
    }
  },
  filters: { localeTypes },
  methods: {
    onBack() {
      this.$router.replace({ name: "connections-overview-easy" });
    },
    onSelect(item) {
      connectorAuthorize({
        category: this.category,
        definitionId: item.id
      }).then(() => {
        this.onBack();
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

section {
  .section();
  max-width: 0;
}
</style>
