import { Types, localeTypes } from "../models/definitions";

export { Types, localeTypes };

/**
 * 获取相对 category。
 * 例如输入 source，返回 destination。
 * @param {String} type 连接器 category
 * @returns {String} opposite type
 */
export function oppositeTypeOf(type) {
  switch (type) {
    case Types.SOURCE:
      return Types.DESTINATION;
    case Types.DESTINATION:
      return Types.SOURCE;
    default:
      return "";
  }
}

/**
 * 获取相对 category 的中文名。
 * @param {String} type 连接器 category
 * @returns {String} 相对 category 的中文名称
 */
export function localeOppositeTypes(type) {
  return localeTypes(oppositeTypeOf(type));
}

/**
 * 根据 connector category 决定读取 connection 的 sourceId 或 destinationId。
 * 例如 source connector，读取 sourceId。
 * @param {String} type 连接器 category
 * @returns sourceId | destinationId
 */
export function connectorIdPropOfType(type) {
  switch (type) {
    case Types.SOURCE:
      return "sourceId";
    case Types.DESTINATION:
      return "destinationId";
    default:
      return null;
  }
}

/**
 * 根据 connector category 决定读取 connection 相对的 sourceId 或 destinationId。
 * 例如 source connector，读取 destinationId。
 * @param {String} type 连接器 category
 * @returns sourceId | destinationId
 */
export function connectorIdPropOfOppositeType(type) {
  return connectorIdPropOfType(oppositeTypeOf(type));
}
