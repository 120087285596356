import showContext from "@/components/contextify";
import SyncModeOption from "./SyncModeOption";

export const presets = {
  "full_refresh|overwrite": {
    label: "全量重写",
    description: "从数据源全量读取，重写目标库数据",
    value: ["full_refresh", "overwrite"]
  },
  "full_refresh|append": {
    label: "全量追加",
    description: "从数据源全量读取，追加至目标库",
    value: ["full_refresh", "append"]
  },
  "incremental|append": {
    label: "增量追加",
    description: "从数据源增量读取未同步过的数据，追加至目标库",
    value: ["incremental", "append"]
  },
  "incremental|append_dedup": {
    label: "增量更新",
    description:
      "从数据源增量读取未同步过的数据，根据所选主键判断应更新目标库数据，还是追加",
    value: ["incremental", "append_dedup"]
  }
};

export function localeMode(config) {
  const key = [config.syncMode, config.destinationSyncMode].join("|");
  const preset = presets[key];
  return preset ? preset.label : null;
}

export function selectMode(opts) {
  const selectedKey = (opts.mode || []).join("|");
  delete opts.mode;
  return new Promise(resolve => {
    const onSelect = option => {
      resolve(option.value);
      if (vm) {
        vm.hide();
      }
    };
    const context = {
      functional: true,
      render() {
        const { sourceModes = [], destinationModes = [] } = opts || {};
        return Object.keys(presets).map(key => {
          const option = presets[key] || {};
          const modes = option.value || [];
          const supportModeOption =
            sourceModes.includes(modes[0]) &&
            destinationModes.includes(modes[1]);
          if (!supportModeOption) {
            return null;
          }
          return (
            <SyncModeOption
              selected={key === selectedKey}
              option={option}
              onSelect={onSelect}
            />
          );
        });
      }
    };
    const vm = showContext(context, opts);
  });
}
