const Status = {
  active: 1,
  stoped: 0,
  aborted: -1
};

export default function(request, opts) {
  const options = Object.assign(
    {
      interval: 1800,
      // 立即发送第一个请求，否则等待 interval ms
      leading: true
    },
    opts
  );
  let count = 0;
  let timer = null;
  let status = Status.active;
  let result = null;

  const queue = () => {
    clearTimeout(timer);
    timer = setTimeout(() => polling.start(), options.interval);
  };
  const polling = {
    get count() {
      return count;
    },
    get status() {
      return status;
    },
    /**
     * 立即开始轮询
     */
    async start() {
      if (status === Status.aborted) {
        throw new Error("aborted");
      }
      status = Status.active;
      result = await request(count, result);
      count += 1;
      if (status === Status.active) {
        queue();
      }
      return result;
    },
    async once() {
      if (status === Status.aborted) {
        throw new Error("aborted");
      }
      result = await request(count, result);
      count += 1;
      return result;
    },
    /**
     * 激活轮询，将在 interval ms 后开始发送请求
     */
    active() {
      queue();
    },
    /**
     * 停止轮询
     */
    stop() {
      clearTimeout(timer);
      status = Status.stoped;
    },
    /**
     * 终止轮询，终止后不可再开始轮询
     */
    abort() {
      status = Status.aborted;
      clearTimeout(timer);
    }
  };
  if (options.leading) {
    polling.start();
  } else {
    queue();
  }
  return polling;
}
