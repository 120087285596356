<template>
  <div class="header">
    <div class="header-left">
      <Add @add="onAdd" />
      <Conditions />
    </div>
    <div class="header-right">
      <a-skeleton
        v-if="loading"
        active
        :paragraph="{ rows: 1, width: 230 }"
        :title="false"
      />
      <Library v-else :connector="connector" @setting="onSetting" />
    </div>
  </div>
</template>

<script>
import connectorLibrary from "@/views/connections/components/modal-connector-library";
import Add from "./Add";
import Conditions from "./Conditions";
import Library from "./Library";

export default {
  components: { Add, Conditions, Library },
  props: {
    loading: Boolean,
    connector: Object
  },
  methods: {
    onAdd() {
      if (!this.connector.id) {
        this.tips();
        return;
      }
      this.$router.push({ name: "connector-creation-easy" });
    },
    onSetting(connector) {
      if (!this.connector.id) {
        this.tips();
        return;
      }
      connectorLibrary({ connector });
    },
    tips() {
      this.$error({
        title: (
          <span style="font-size:17px;font-weight:700;">未分配主数据库</span>
        ),
        content: <p>请联系客服咨询问题</p>
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.header {
  .flex();

  &-left {
    .flex();
    margin-right: 30px;
  }
}
</style>
