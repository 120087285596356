<template>
  <a-row type="flex" align="middle" :gutter="16">
    <a-col :span="5">
      <a-input-search
        v-model="filters.search"
        placeholder="搜索"
        @change="$emit('input', filters)"
      />
    </a-col>
    <a-col flex="1 1 0">
      <a-radio-group v-model="filters.type" @change="$emit('input', filters)">
        <a-radio value="all">全部</a-radio>
        <a-radio value="selected">已选项</a-radio>
        <a-radio value="unselected">未选项</a-radio>
      </a-radio-group>
    </a-col>
    <a-col>
      <a-button
        icon="reload"
        type="primary"
        size="small"
        :loading="loading"
        @click="$emit('reload')"
      >
        重新加载
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    value: Object,
    loading: Boolean
  },
  data() {
    return {
      filters: Object.assign(
        {
          search: null,
          type: "all"
        },
        this.value
      )
    };
  }
};
</script>
