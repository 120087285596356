import { TimeUnits } from "@/views/connections/utils/sync-frequency";
import { localeSyncFreq } from "@/views/connections/utils/sync-frequency";

export class Schedule {
  constructor(units, timeUnit, startTime) {
    this.units = units || null;
    this.timeUnit = timeUnit || TimeUnits.minutes;
    this.startTime = startTime || null;
  }
  // ⚠️ value 只需要 units 和 timeUnit。startTime 会导致无法匹配上对应的频率选项
  get value() {
    const { units, timeUnit } = this;
    return JSON.stringify({ units, timeUnit });
  }
  get label() {
    return localeSyncFreq(this);
  }
  // 手动同步关键字
  static MANUAL = "manual";
  // 自定义同步关键字
  static CUSTOM = "custom";
  // 判断两个 schedule 是否相同时仅需判断 units 和 timeUnit
  static equal(a, b) {
    if (a && b) {
      return a.units == b.units && a.timeUnit == b.timeUnit;
    }
    return false;
  }
  static from(obj) {
    obj = obj || {};
    return new Schedule(obj.units, obj.timeUnit, obj.startTime);
  }
}

export const presets = [
  new Schedule(5, TimeUnits.minutes),
  new Schedule(15, TimeUnits.minutes),
  new Schedule(30, TimeUnits.minutes),
  new Schedule(1, TimeUnits.hours),
  new Schedule(2, TimeUnits.hours),
  new Schedule(3, TimeUnits.hours),
  new Schedule(4, TimeUnits.hours),
  new Schedule(8, TimeUnits.hours),
  new Schedule(12, TimeUnits.hours),
  new Schedule(1, TimeUnits.days),
  new Schedule(1, TimeUnits.weeks),
  new Schedule(1, TimeUnits.months)
];

/**
 * 将 schedule 对象转换为 字符串，便于 <a-select /> 控件匹配正确选项
 * @param {Object} schedule connection 的 schedule 对象
 * @returns {String} 与同步频率选项相匹配的字符串
 */
export function matchSchedule(schedule) {
  // schedule 为 null 时视为手动同步
  if (!schedule || !schedule.units) {
    return Schedule.MANUAL;
  }
  // 从预定义选项中查找与传入 schedule.units 和 schedule.timeUnit 相同的选项
  const preset = presets.find(item => Schedule.equal(item, schedule));
  if (preset) {
    return preset.value;
  }
  // 否则视为自定义频率
  return Schedule.CUSTOM;
}

/**
 * 将 matchSchedule 函数返回的字符串转义为 schedule 对象
 * @param {String} type matchSchedule 函数返回的 schedule type
 * @returns {Schedule} schedule 对象，手动同步时将返回 null
 */
export function convertFrequencyType(type) {
  // 手动同步返回 null
  if (type == Schedule.MANUAL) {
    return null;
  }
  // 从 presets 中查找是否存在与 type 匹配的选项
  // 存在则生成相应 schedule，注意 new 新对象，避免污染 presets
  const preset = presets.find(item => item.value == type);
  if (preset) {
    return Schedule.from(preset);
  }
  // 其余为自定义频率，默认每 10 分钟
  return new Schedule(10, TimeUnits.minutes);
}

export default presets;
