<template>
  <div>
    <ModeSwitch />
    <section>
      <ConnrctorHeader
        :loading="loading"
        :connector="mainDestination"
        @filter="status => (connectionStatus = status)"
      />
      <ConnrctorCards
        :loading="loading"
        :connections="connections"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
        @sort="onSort"
      />
    </section>
  </div>
</template>

<script>
import ModeSwitch from "./components/mode-switch";
import ConnrctorHeader from "./components/connector-header";
import ConnrctorCards from "./components/connector-cards";
import connectors from "./models/connectors";

export default {
  components: {
    ModeSwitch,
    ConnrctorHeader,
    ConnrctorCards
  },
  data() {
    return { connectionStatus: null };
  },
  computed: {
    loading() {
      return connectors.loading;
    },
    mainDestination() {
      return connectors.mainDestination;
    },
    connections() {
      const connector = this.mainDestination;
      const connections = connectors.getConnectionsOfConnectorStatus(
        connector.id,
        this.connectionStatus
      );
      return connections || [];
    }
  },
  methods: {
    onDragStart() {
      connectors.setDragging(true);
    },
    onDragEnd() {
      connectors.setDragging(false);
    },
    onSort(connections) {
      connectors.sortConnection(connections);
    }
  }
};
</script>

<style lang="less" scoped>
@import "./assets/vars.less";

section {
  .section();
}
</style>
