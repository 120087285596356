<template>
  <input
    v-if="editing"
    v-focus
    class="field-input"
    :placeholder="label"
    :value="value"
    @blur="onBlur"
    @keydown.enter="onEnter"
    @keydown.esc="onEsc"
  />
  <span v-else :title="label" @click="editing = true">{{ label }}</span>
</template>

<script>
import { focus } from "@/utils/directives";

export default {
  directives: { focus },
  props: {
    defaultName: String,
    value: String
  },
  data() {
    return {
      editing: false
    };
  },
  computed: {
    label() {
      return this.value || this.defaultName;
    }
  },
  methods: {
    onBlur(evt) {
      let value = evt.target.value;
      value = String(value).trim();
      this.$emit("input", value || null);
      this.editing = false;
    },
    onEnter(evt) {
      evt.target.blur();
    },
    onEsc(evt) {
      evt.target.value = this.value || "";
      evt.target.blur();
    }
  }
};
</script>

<style lang="less" scoped>
.field-input {
  width: 95%;
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 4px 8px;
}
</style>
