<template>
  <div>
    <Header
      :connection-id="connectionId"
      @reset="$listeners.reset"
      @sync="$listeners.sync"
    />
    <RecordRow
      v-for="(item, index) in filteredRecords"
      :key="index"
      :record="item"
      :category="category"
    />
    <a-skeleton v-if="loading" active style="padding: 30px" />
    <a-empty
      v-else-if="!filteredRecords.length"
      :image="ImageEmpty"
      :image-style="{ height: '220px', marginTop: '100px' }"
    />
  </div>
</template>

<script>
import ImageEmpty from "@/assets/images/ai_add.svg";
import Header from "./RecordsHeader";
import RecordRow from "./RecordsRow";

export default {
  components: { Header, RecordRow },
  props: {
    records: Array,
    category: String,
    loading: Boolean,
    // 连接 id，删选同步历史
    connectionId: [String, Number]
  },
  data() {
    return { ImageEmpty };
  },
  computed: {
    filteredRecords() {
      const id = this.connectionId;
      if (id) {
        return this.records.filter(item => item.connectionId == id);
      }
      return this.records;
    }
  }
};
</script>

<style lang="less" scoped></style>
