<template>
  <div>
    <div
      :class="['list-item', 'check-all', { active: !checked }]"
      @click="checked = null"
    >
      <span>
        全部{{ relatedConnectorLabel }}
        <i class="ndl-icon-chevrons-right"></i>
      </span>
    </div>
    <ConnectorCard
      v-for="item in connections"
      :key="item.id"
      :connector-id="item[idProp]"
      :class="['list-item', { active: item.id == checked }]"
      @click.native="checked = item.id"
    >
      <i class="ndl-icon-chevron-right"></i>
    </ConnectorCard>
  </div>
</template>

<script>
import ConnectorCard from "@/views/connections/components/connector-card";
import {
  localeOppositeTypes,
  connectorIdPropOfOppositeType
} from "@/views/connections/utils/connector-category";

export default {
  components: { ConnectorCard },
  props: {
    connections: Array,
    category: String,
    // 选中的连接
    value: [String, Number]
  },
  data() {
    return {
      checked: this.value
    };
  },
  computed: {
    relatedConnectorLabel() {
      return localeOppositeTypes(this.category);
    },
    idProp() {
      return connectorIdPropOfOppositeType(this.category);
    }
  },
  watch: {
    checked(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.list-item {
  height: @row-height;
  padding: 0 8px;
  cursor: pointer;
  transition: all 0.2s;

  &.check-all {
    display: flex;
    align-items: center;
    padding-left: 22px;
    justify-content: space-between;
    color: @local-title-color;
  }
  &:hover {
    background: #f5f5f5;
  }
  &.active {
    background: @blue-1;
  }
  &.active .ndl-icon-chevron-right {
    display: block;
  }
  .ndl-icon-chevron-right {
    display: none;
    color: @disabled-color;
  }

  /deep/ img {
    width: 38px;
    height: 38px;
  }
  /deep/ .title {
    font-size: 14px;
  }
  /deep/ .desc {
    font-size: 12px;
  }
}
</style>
