<template>
  <div class="header">
    <img v-if="imgSrc" :src="imgSrc" class="header-img" />
    <span class="header-title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: String,
    title: String
  }
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;

  &-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  &-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }
}
</style>
