<template>
  <a-modal
    class="model-content-custom"
    :width="1000"
    :footer="null"
    :centered="true"
    :mask-closable="false"
  >
    <ModelHeader slot="title" :img-src="connectorIcon" :title="connectorName" />
    <div class="model-body">
      <Transform :connector="connector" />
    </div>
  </a-modal>
</template>

<script>
import ModelHeader from "@/views/connections/components/modal-common-header";
import { Transform } from "@/views/connections/components/connector-details";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  components: { ModelHeader, Transform },
  props: {
    connector: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    connectorIcon() {
      return connectorList.getConnectorIcon(this.connector.id);
    },
    connectorName() {
      return connectorList.getConnectorName(this.connector.id) + " - 数据转换";
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.model-body {
  padding: 20px;
}
.model-body /deep/ .ant-empty {
  margin: 60px;
}
</style>
