<template>
  <div class="oper" @click.stop>
    <a-tooltip title="重新授权">
      <Oper class="btn" @click="$parent.$emit('reauthorize', item)">
        <span class="ndl-icon-tool"></span>
      </Oper>
    </a-tooltip>

    <a-tooltip title="连接设置">
      <Oper class="btn" @click="$parent.$emit('setting', item)">
        <span class="ndl-icon-settings"></span>
      </Oper>
    </a-tooltip>

    <a-tooltip title="同步历史">
      <Oper class="btn" @click="$parent.$emit('history', item)">
        <span class="ndl-icon-file-text"></span>
      </Oper>
    </a-tooltip>

    <a-tooltip title="数据转换">
      <Oper class="btn" @click="$parent.$emit('transform', item)">
        <span class="ndl-icon-repeat"></span>
      </Oper>
    </a-tooltip>

    <a-tooltip title="删除连接">
      <Oper class="btn" @click="$parent.$emit('delete', item)">
        <a-icon type="delete" class="red-color" />
      </Oper>
    </a-tooltip>
  </div>
</template>

<script>
import Oper from "@/views/connections/components/common-btn";

export default {
  components: { Oper },
  props: {
    item: Object
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.oper {
  .flex();
  justify-content: center;
  height: 46px;
  padding: 0 10px;

  .btn {
    color: @blue-5;
    padding: 5px 12px;
    &:hover {
      color: @blue-5;
    }
  }
}
.red-color {
  color: @red-5;
}
</style>
