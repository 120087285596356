<template>
  <Draggable
    v-model="connectionList"
    :animation="300"
    @start="$emit('dragstart')"
    @end="$emit('dragend')"
    class="cards"
  >
    <a-skeleton v-if="loading" active :paragraph="{ rows: 4 }" />
    <div v-else-if="!connectionList.length" class="empty-body">
      <a-empty
        description=""
        :image="ImageEmpty"
        :image-style="{ height: '250px', margin: '60px 0 30px' }"
      />
    </div>
    <template v-else>
      <Card
        v-for="item in connectionList"
        :key="item.id"
        :item="item"
        @sync="onSync"
        @stop="onStop"
        @reauthorize="onReauthorize"
        @setting="onSetting"
        @history="onHistory"
        @transform="onTransform"
        @delete="onDelete"
      />
      <div class="placeholder"></div>
      <div class="placeholder"></div>
    </template>
  </Draggable>
</template>

<script>
import Draggable from "vuedraggable";
import * as APIJob from "@/api/connections";
import ImageEmpty from "@/assets/images/ai_add.svg";
import { connectorList } from "@/views/connections/models/connectors";
import connectorReauthorize from "@/views/connections/components/modal-connector-reauthorize";
import connectionSetting from "@/views/connections/components/modal-connection-setting";
import connectionHistory from "@/views/connections/components/modal-connection-history";
import connectorTransform from "@/views/connections/components/modal-connector-transform";
import Card from "./Card";

export default {
  components: { Draggable, Card },
  props: {
    loading: Boolean,
    connections: Array
  },
  data() {
    return { ImageEmpty };
  },
  computed: {
    connectionList: {
      get() {
        return this.connections;
      },
      set(connections) {
        this.$emit("sort", connections);
      }
    }
  },
  methods: {
    async onSync(connection, onFailed) {
      try {
        await APIJob.runJob(connection.id);
      } catch {
        onFailed();
        this.$message.error("同步失败");
      }
    },
    async onStop(connection, onFailed) {
      try {
        await APIJob.stopJob(connection.id);
      } catch {
        onFailed();
        this.$message.error("取消失败");
      }
    },
    onReauthorize(connection) {
      connectorReauthorize({
        connector: connectorList.getConnector(connection.sourceId)
      });
    },
    onSetting(connection) {
      connectionSetting({
        connector: connectorList.getConnector(connection.sourceId),
        connection: connection
      });
    },
    onHistory(connection) {
      connectionHistory({
        connector: connectorList.getConnector(connection.sourceId),
        connections: [connection]
      });
    },
    onTransform(connection) {
      connectorTransform({
        connector: connectorList.getConnector(connection.sourceId)
      });
    },
    onDelete(connection) {
      const sourceName = connectorList.getConnectorName(connection.sourceId);
      const destName = connectorList.getConnectorName(connection.destinationId);
      this.$confirm({
        title: "确认删除",
        content: `确定要删除「${sourceName}」与「${destName}」的连接吗？`,
        okType: "danger",
        onOk: async () => {
          try {
            await connectorList.deleteConnection(connection.id);
            this.$message.success("删除成功");
          } catch (err) {
            const resData = err.data || {};
            this.$notification.error({
              message: "操作失败",
              description: resData.msg
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.empty-body {
  height: 100%;
}
.cards {
  .flex();
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  width: 100%;

  .placeholder {
    width: @local-cart-width;
    margin: 0 @local-cart-margin;
  }
}
</style>
