<template>
  <div :class="['wrapper', jobStatus]">
    <div :class="['record', { expanded }]" @click="expanded = !expanded">
      <Connector
        :connection-id="record.connectionId"
        :category="category"
        class="field-flex"
      />
      <Status :record="record" class="field-flex" />
      <Datetime :record="record" class="field-fixed" />
      <i class="ndl-icon-chevron-right field-fixed"></i>
    </div>
    <!-- 展开日志 -->
    <Terminal v-if="expanded" :record="record" />
  </div>
</template>

<script>
import Status from "@/views/connections/components/record-status";
import Terminal from "@/views/connections/components/job-terminal";
import Connector from "./RecordsConnector";
import Datetime from "./RecordsDatetime";

export default {
  components: { Connector, Status, Datetime, Terminal },
  props: {
    record: Object,
    category: String
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    jobStatus() {
      const job = this.record.job || {};
      return job.status;
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.wrapper {
  & + & {
    border-top: solid 1px #ebebeb;
  }
  &:hover {
    background: @blue-1;
  }
  &.failed:hover {
    background: @red-1;
  }
}
.record {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: @row-height;
  cursor: pointer;
  white-space: nowrap;
  .field-flex {
    flex: 1 1 0;
    width: 0;
    overflow: hidden;
  }
  .field-fixed {
    flex-shrink: 0;
    overflow: hidden;
  }
  .ndl-icon-chevron-right {
    font-size: 16px;
    color: @text-color-secondary;
    margin-left: 8px;
  }
  &.expanded .ndl-icon-chevron-right {
    transform: rotate(90deg);
  }
}
</style>
