<template>
  <div>
    <!-- 表头 -->
    <ListHeader :category-label="categoryLabel" :count="connections.length" />
    <!-- 表体 -->
    <ListItem
      v-for="item in connections"
      :key="item.id"
      :item="item"
      :category-label="categoryLabel"
      :id-prop="idProp"
    />
    <!-- 空状态 -->
    <a-empty
      v-if="!connections.length"
      description=""
      :image="ImageEmpty"
      :image-style="{ height: '220px', margin: '60px' }"
    />
  </div>
</template>

<script>
import { string } from "@/utils/props-validator";
import ImageEmpty from "@/assets/images/ai_add.svg";
import {
  Types,
  localeOppositeTypes,
  connectorIdPropOfOppositeType
} from "@/views/connections/utils/connector-category";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";

export default {
  components: { ListHeader, ListItem },
  props: {
    connections: {
      type: Array,
      required: true
    },
    category: string.enum(Types.SOURCE, Types.DESTINATION)
  },
  data() {
    return { ImageEmpty };
  },
  computed: {
    categoryLabel() {
      return localeOppositeTypes(this.category);
    },
    idProp() {
      return connectorIdPropOfOppositeType(this.category);
    }
  },
  methods: {
    onActiveChange(connection, bool) {
      this.$emit(bool ? "enable" : "disable", connection);
    }
  }
};
</script>
