<template>
  <Skeleton :title="title">
    <!-- 底部按钮 -->
    <a-button v-if="backable && current" slot="footer-left" @click="prev()">
      上一步
    </a-button>
    <a-button slot="footer-right" @click="$router.back()">
      取消
    </a-button>
    <a-button
      slot="footer-right"
      type="primary"
      :disabled="disabled"
      :loading="loading"
      @click="next()"
    >
      {{ current > 1 ? saveButtonLabel : "继续" }}
    </a-button>

    <!-- 步骤条及表单 -->
    <div ref="contentWrapper" class="content-wrapper">
      <Steps
        v-model="current"
        :source-id="connection.sourceId"
        :destination-id="connection.destinationId"
      />
      <component
        ref="stepContent"
        :is="StepCurrent"
        :connection="connection"
        @validate="disabled = !$event"
      />
    </div>
  </Skeleton>
</template>

<script>
import Skeleton from "@/views/connections/components/common-skeleton";
import Steps from "./Steps";
import StepsSource from "./StepsSource";
import StepsDestination from "./StepsDestination";
import StepsConfig from "./StepsConfig";

export default {
  components: { Skeleton, Steps },
  props: {
    title: String,
    connection: {
      type: Object,
      required: true
    },
    backable: Boolean,
    saveButtonLabel: {
      type: String,
      default: "完成"
    }
  },
  data() {
    return {
      current: 0,
      disabled: true,
      loading: false
    };
  },
  computed: {
    StepCurrent() {
      const list = [StepsSource, StepsDestination, StepsConfig];
      return list[this.current] || StepsConfig;
    }
  },
  watch: {
    current() {
      this.$nextTick(() => {
        this.$refs.contentWrapper.scrollIntoView();
      });
    }
  },
  methods: {
    async next() {
      try {
        this.loading = true;
        const stepContent = this.$refs.stepContent;
        await stepContent.next();
      } finally {
        this.loading = false;
      }
    },
    prev() {
      const stepContent = this.$refs.stepContent;
      stepContent.prev();
    }
  }
};
</script>

<style lang="less" scoped>
.content-wrapper {
  max-width: 1360px;
  padding: 48px 24px;
  margin: 0 auto;
}
</style>
