<template>
  <a-tooltip title="主数据库">
    <div class="database" @click="() => $emit('setting', connector)">
      <span class="ndl-icon-edit icon"></span>
      <span class="name">{{ name }}</span>
    </div>
  </a-tooltip>
</template>

<script>
export default {
  props: {
    connector: Object
  },
  computed: {
    name() {
      return this.connector.name || "未分配主数据库";
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.database {
  .flex();
  border-bottom: 1px dashed @blue-6;
  padding: 5px;
  font-size: @local-font-size-md;
  cursor: pointer;

  .icon {
    color: @blue-6;
    margin-right: 8px;
  }
  .name {
    font-weight: 700;
    max-width: 200px;
    .ndl-ellipsis();
  }
}
</style>
