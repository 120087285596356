<template>
  <div class="content">
    <div class="content-top">
      <img
        class="img"
        :src="connectorIcon || '/images/default_connector_icon.svg'"
      />
      <div class="content-top-text">
        <div class="title">{{ connectorName }}</div>
        <div class="status"><JobTag :status="item.latestSyncJobStatus" /></div>
      </div>
    </div>
    <div class="content-bottom">
      <div class="content-detail">
        <span class="content-detail-label">同步频率</span>
        <span class="content-detail-text">{{
          item.schedule | localeFreq
        }}</span>
      </div>
      <div class="content-detail">
        <span class="content-detail-label">最近同步</span>
        <span class="content-detail-text">{{
          item.latestSyncJobCreatedAt | syncJobTime
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { connectorList } from "@/views/connections/models/connectors";
import localeFreq from "@/views/connections/utils/sync-frequency";
import JobTag from "@/views/connections/components/job-tag";

export default {
  components: { JobTag },
  props: {
    item: Object
  },
  filters: {
    localeFreq,
    syncJobTime(time) {
      return time ? time : "未同步";
    }
  },
  computed: {
    connectorIcon() {
      return connectorList.getConnectorIcon(this.item.sourceId);
    },
    connectorName() {
      return connectorList.getConnectorName(this.item.sourceId);
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

@local-wh: 45px;

.content {
  padding: 25px 25px 10px;

  &-top {
    .flex();

    .img {
      width: @local-wh;
      height: @local-wh;
      margin-right: 15px;
    }
    &-text {
      flex: 1;

      .title {
        width: 220px;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        .ndl-ellipsis();
      }
      .status {
        margin-top: 10px;
      }
    }
  }

  &-bottom {
    margin-top: 10px;
  }

  &-detail {
    .flex();
    font-size: 13px;

    &-label {
      color: #86909c;
      margin-right: 12px;
    }
    &-text {
      color: #000000;
    }
  }
  &-detail:nth-child(2) {
    margin-top: 3px;
  }
}
</style>
