import polling from "@/utils/polling";

/**
 * 维护轮询队列，并代理相关方法
 */
export default class PollingProxy {
  constructor() {
    this.queue = [];
  }
  push(request, opts) {
    this.queue.push(polling(request, opts));
  }
  _invoke(action) {
    for (const item of this.queue) {
      if (typeof item[action] === "function") {
        item[action]();
      }
    }
  }
  start() {
    this._invoke("start");
  }
  once() {
    this._invoke("once");
  }
  stop() {
    this._invoke("stop");
  }
  active() {
    this._invoke("active");
  }
  abort() {
    this._invoke("abort");
  }
}
