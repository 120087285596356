<template>
  <span v-if="restCount" class="units" @click="$emit('select')">
    <a-tag v-if="firstKey" :color="color" :title="firstKey" class="first">
      {{ firstKey }}
    </a-tag>
    <a-tag v-if="restCount > 1" :color="color" :title="allKeys" class="last">
      +{{ restCount }}
    </a-tag>
  </span>
  <!-- restCount 为空的话提示用户选择-->
  <!-- a.danger 为全局样式 -->
  <a v-else @click="$emit('select')" class="danger">
    请选择
  </a>
</template>

<script>
export default {
  props: {
    keys: Array,
    color: String
  },
  computed: {
    firstKey() {
      const keys = this.keys || [];
      return String(keys[0] || "");
    },
    allKeys() {
      const keys = this.keys || [];
      return keys.join(", ");
    },
    restCount() {
      const keys = this.keys || [];
      return keys.length;
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.units {
  display: flex;
  align-items: center;
  padding-right: 4px;
}
.ant-tag {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0;
  cursor: pointer;
  &.last {
    flex-shrink: 0;
    margin-left: 4px;
  }
}
</style>
