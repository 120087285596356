<script>
import Editor from "./components/connection-editor";
import sessions from "./utils/sessions";
import normalize from "./utils/normalize-connection";

export default {
  data() {
    return {
      connection: normalize({
        sourceId: sessions.sourceId(),
        destinationId: sessions.destinationId()
      })
    };
  },
  render() {
    return <Editor backable title="添加连接" connection={this.connection} />;
  }
};
</script>
