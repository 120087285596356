<template>
  <div class="terminal">
    <div v-if="loading" class="output">
      <a-skeleton active />
    </div>
    <LazyLog class="output" v-else :lines="logs" />
    <div v-if="attempts.length > 1" class="status">
      <Attempts v-model="attemptIndex" :attempts="attempts" />
      <span>{{ jobinfo }}</span>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/connections";
import * as helpers from "@/views/connections/utils/helpers";
import LazyLog from "@/components/vue-lazylog";
import Attempts from "./TerminalAttempts";

export default {
  components: { LazyLog, Attempts },
  props: {
    record: Object,
    getJobLog: Function
  },
  data() {
    const attempts = this.record.attempts || [];
    return {
      jobDetails: {},
      loading: true,
      attemptIndex: attempts.length - 1
    };
  },
  async created() {
    try {
      this.loading = true;
      const job = this.record.job || {};
      if (this.getJobLog) {
        this.jobDetails = await this.getJobLog(job, this.record);
      } else {
        const { data } = await API.getJobLog(job.id);
        this.jobDetails = data;
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    attempts() {
      return this.record.attempts || [];
    },
    jobinfo() {
      return helpers.jobinfo(this.record, this.attemptIndex);
    },
    logs() {
      const attempts = this.jobDetails.attempts || [];
      const attempt = attempts[this.attemptIndex];
      return attempt ? attempt.logs.logLines : [];
    }
  }
};
</script>

<style lang="less" scoped>
@height: 420px;
.terminal {
  background: #fafafa;
  height: @height;
  animation: expand 0.3s;
  display: flex;
  flex-direction: column;

  .output {
    flex: 1 1 0;
    height: 0;
    overflow: auto;
    padding: 16px;
  }
  .status {
    height: 32px;
    font-size: 12px;
    flex-shrink: 0;
    background: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    color: #8f8f8f;
  }
}
@keyframes expand {
  0% {
    height: 0px;
  }
  100% {
    height: @height;
  }
}
</style>
