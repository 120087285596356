<template>
  <a-form-item label="选择需要同步的表">
    <Filters
      v-model="filters"
      :loading="loading"
      @reload="getConnectorStreams()"
    />
    <a-skeleton v-if="loading && streams.length === 0" active />
    <Schema
      v-else
      :streams="filteredStreams"
      :get-scroll-element="getSkeletonBody"
      :stream-name-prefix="connection.prefix"
      :destination-modes="
        destinationSpecification.supportedDestinationSyncModes
      "
    />
  </a-form-item>
</template>

<script>
import Schema from "@/views/connections/components/connection-schema";
import connectorList from "@/views/connections/models/connectors";
import definitionList from "@/views/connections/models/definitions";
import { getConnectionDetail, getConnectorSchema } from "@/api/connections";
import Filters from "./SyncCatalogFilters";
import * as helper from "./helper";

export default {
  components: { Filters, Schema },
  inject: ["getSkeletonBody"],
  props: {
    connection: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filters: {},
      loading: false,
      destinationSpecification: {},
      streams: []
    };
  },
  computed: {
    filteredStreams() {
      let streams = this.streams;
      const { search, type } = this.filters;
      if (type === "selected") {
        streams = streams.filter(item => item.config.selected);
      }
      if (type === "unselected") {
        streams = streams.filter(item => !item.config.selected);
      }
      if (search) {
        streams = streams.filter(item => {
          const streamName = item.stream.name;
          return helper.includes(streamName, search);
        });
      }
      return streams;
    }
  },
  created() {
    this.getDestinationSpecification();
    if (this.connection.id) {
      this.getSyncCatalog();
    } else {
      this.getConnectorStreams();
    }
  },
  watch: {
    "connection.id"() {
      this.getSyncCatalog();
    },
    "connection.sourceId"() {
      this.getConnectorStreams();
    },
    "connection.destinationId"() {
      this.getDestinationSpecification();
    },
    streams(streams) {
      this.connection.syncCatalog = { streams };
    },
    destinationSpecification(spec) {
      spec = spec || {};
      this.connection.normalization = spec.supportsNormalization ? "basic" : "";
    }
  },
  methods: {
    async getSyncCatalog() {
      try {
        this.loading = true;
        const { data } = await getConnectionDetail(this.connection.id);
        const streams = (data.syncCatalog || {}).streams || [];
        this.streams = helper.mergeStreams(streams, this.streams);
        if (!this.streams.length) {
          await this.getConnectorStreams();
        }
      } finally {
        this.loading = false;
      }
    },
    async getConnectorStreams() {
      try {
        this.loading = true;
        const { data } = await getConnectorSchema(this.connection.sourceId);
        this.streams = helper.mergeStreams(data.streams, this.streams);
      } finally {
        this.loading = false;
      }
    },
    async getDestinationSpecification() {
      const destinationId = this.connection.destinationId;
      const definitionId = connectorList.getDefinitionId(destinationId);
      if (!definitionId) {
        return;
      }
      const data = await definitionList.getSpecification(definitionId);
      this.destinationSpecification = data;
    }
  }
};
</script>
