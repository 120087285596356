<template>
  <transition name="context-motion" @after-leave="afterLeave()">
    <div
      v-show="visible"
      v-click-outside="{
        handler: hide,
        middleware: contextConfig.middleware || defaultMiddleware,
        events: contextConfig.events || ['touchstart', 'mousedown', 'keydown']
      }"
      :class="[
        'contextify-container',
        contextConfig.class,
        {
          light: contextConfig.light,
          'backdrop-filter': isBackdropFilterSurport,
          'keep-on-hide': contextConfig.keepOnHide
        }
      ]"
      :placement="placement"
      :style="[style, contextConfig.style]"
    >
      <slot>
        <ContentComponent
          v-bind="config"
          v-on="config.on"
          :hide-context="hide"
          @hook:mounted.once="onMounted()"
        />
      </slot>
    </div>
  </transition>
</template>

<script>
import clickOutside from "v-click-outside";
import Origin from "./def-origin";
import CSSSurports from "@/utils/css-surport";

export default {
  components: {
    // 默认子组件为空
    ContentComponent: {
      render: () => null
    }
  },
  provide() {
    return {
      getContext: () => this,
      getContextConfig: () => this.config
    };
  },
  directives: { clickOutside: clickOutside.directive },
  data() {
    return {
      isBackdropFilterSurport: CSSSurports.backdropFilter,
      visible: false,
      origin: null,
      /**
       * @param {Boolean} config.light 轻奢风格😝，降低 context 透明度
       * @param {HTMLElement | MouseEvent} config.source context 原点参考对象
       * @param {Object | Boolean} config.inverse 对齐方式反转
       * @param {Boolean} config.keepOnHide context 隐藏时是否销毁实例
       * @param {HTMLELement} config.container context DOM append 的父元素，默认 body
       * @param {Array | Object | String} config.class context 附加的 class
       * @param {CSSProperties} config.style context 附加内联样式
       */
      config: {},
      placement: "bottom-right",
      container: document.body
    };
  },
  computed: {
    style() {
      const origin = this.origin;
      if (!origin) return null;
      const { x, y, offsetX, offsetY } = origin;
      return {
        left: Math.max(x + offsetX, 10) + "px",
        top: Math.max(y + offsetY, 10) + "px"
      };
    },
    contextConfig() {
      return this.config || {};
    }
  },
  mounted() {
    this.onMounted();
  },
  beforeDestroy() {
    this.$el && this.$el.remove();
  },
  methods: {
    onMounted() {
      this.mountToContainer();
      this.visible = true;
      this.$nextTick(this.autoPlacement);
    },
    /**
     * 初始化 context 位置
     * @param {MouseEvent|HTMLElement} opts.source context 原点依赖的鼠标位置或者 DOM 节点
     * @param {Boolean|Object} opts.inverse context 和 DOM 节点边缘对齐方式，默认对齐右下角
     * inverse: true 是 inverse: { x: true, y: true } 的快捷方式
     * @param {Boolean|Object} opts.dir context 展开方向，默认为“右-下”
     * dir: true 是 dir: { x: true, y: true } 的快捷方式
     */
    init(opts) {
      this.update(opts);
    },
    update(opts = {}) {
      opts = Object.assign({}, this.config, opts);
      this.config = opts;
      this.origin = Origin.from(opts.source, opts.inverse);
      this.container = opts.container || document.body;
      if (!this.$el) return;
      this.mountToContainer();
      this.$nextTick(this.autoPlacement);
    },
    mountToContainer() {
      const { container, $el } = this;
      if (!container || !$el) return;
      if ($el.parentNode !== container) container.append($el);
    },
    autoPlacement() {
      const { offsetWidth = 0, offsetHeight = 0 } = this.$el || {};
      const { innerWidth, innerHeight } = window;
      const origin = this.origin || new Origin();
      const { x = 0, y = 0, extentX = 0, extentY = 0 } = origin;
      const dirOpts = this.config.dir || false;
      const dir =
        typeof dirOpts === "boolean"
          ? { x: dirOpts, y: dirOpts }
          : Object.assign({ x: false, y: false }, dirOpts);
      const toggleDirX = dir.x || x + offsetWidth + extentX + 10 > innerWidth;
      const toggleDirY = dir.y || y + offsetHeight + extentY + 10 > innerHeight;
      let v = "bottom";
      let h = "right";
      if (toggleDirX) {
        h = "left";
        origin.setOffsetX(0 - offsetWidth - extentX);
      }
      if (toggleDirY) {
        v = "top";
        origin.setOffsetY(0 - offsetHeight - extentY);
      }
      this.placement = v + "-" + h;

      // 经过上面的初步设置之后，可能还会出现元素没显示完全的情况，这里再重新进行判断和计算一下
      const elRight = origin.x + origin.offsetX + offsetWidth;
      if (elRight >= innerWidth) {
        origin.setOffsetX(origin.offsetX - (elRight - innerWidth) - 10);
      }
      const elBottom = origin.y + origin.offsetY + offsetHeight;
      if (elBottom >= innerHeight) {
        origin.setOffsetY(origin.offsetY - (elBottom - innerHeight) - 10);
      }
    },
    hide() {
      this.visible = false;
    },
    // 等待 context 关闭
    wait() {
      return new Promise(resolve => this.$once("on-hide", resolve));
    },
    show() {
      this.visible = true;
    },
    destroy() {
      this.$destroy();
    },
    afterLeave() {
      this.$emit("on-hide", this);
      if (!this.contextConfig.keepOnHide) this.$destroy();
    },
    defaultMiddleware(event) {
      // 只处理 esc 按键
      if (event.type !== "keydown") return true;
      return event.key === "Escape";
    }
  }
};
</script>

<style lang="less" scoped>
.contextify-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  overflow: auto;
  // &.backdrop-filter {
  //   background-color: rgba(255, 255, 255, 0.8);
  //   backdrop-filter: blur(5px);
  // }
  &.keep-on-hide {
    transition: left 0.2s, top 0.2s;
  }

  // 根据上级指示移除虚化背景效果，2022-04
  // config 中可配置 light: true 使背景透明度更高
  // &.light {
  //   background-color: rgba(255, 255, 255, 0.92);
  // }
  // &.backdrop-filter.light {
  //   background-color: rgba(255, 255, 255, 0.5);
  // }

  &[placement="bottom-right"] {
    transform-origin: 10% 10%;
  }
  &[placement="bottom-left"] {
    transform-origin: 90% 10%;
  }
  &[placement="top-right"] {
    transform-origin: 10% 90%;
  }
  &[placement="top-left"] {
    transform-origin: 90% 90%;
  }
}
.context-motion-enter-active {
  animation: zoom-in 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.context-motion-leave-active {
  animation: zoom-out 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes zoom-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes zoom-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}
</style>
