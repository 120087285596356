<script>
import Name from "./Name";

function isFieldCheckedAsPrimaryKey(config, field) {
  // 结构类似 [["store_city"], ["store_name"]]
  const primaryKeys = config.primaryKey || [];
  return primaryKeys.some(item => item[0] === field.fieldName);
}

function isFieldCheckedAsCursorField(config, field) {
  // 结构类似 ["the_date"]，一般为单选
  const cursorFields = config.cursorField || [];
  return cursorFields.some(item => item === field.fieldName);
}

function checkAsPrimaryKey(config, field, checked) {
  // primaryKey 为多选，结构类似 [["store_city"], ["store_name"]]
  const fieldName = field.fieldName;
  const primaryKeys = (config.primaryKey || []).filter(
    item => item[0] !== fieldName
  );
  if (checked) {
    primaryKeys.push([fieldName]);
  }
  config.primaryKey = primaryKeys;
}

function checkAsCursorField(config, field) {
  // cursorField 为单选
  config.cursorField = [field.fieldName];
}

export default {
  functional: true,
  components: { Name },
  render(h, context) {
    const source = context.props.source;
    const config = context.props.config;
    return [
      <span class="cell">
        <Name
          name={source.fieldNameAlias || source.fieldName}
          alias={source.title}
        />
      </span>,
      <span class="cell minor">{[].concat(source.type).join(" | ")}</span>,
      <span class="cell">{source.fieldName}</span>,
      <span class="cell minor">
        {config.destinationSyncMode === "append_dedup" ? (
          <a-checkbox
            checked={isFieldCheckedAsPrimaryKey(config, source)}
            onInput={checked => checkAsPrimaryKey(config, source, checked)}
          />
        ) : null}
      </span>,
      <span class="cell minor">
        {config.syncMode === "incremental" ? (
          <a-radio
            checked={isFieldCheckedAsCursorField(config, source)}
            onInput={checked => checkAsCursorField(config, source, checked)}
          />
        ) : null}
      </span>
    ];
  }
};
</script>
