<template>
  <div class="wrapper">
    <Connections
      v-model="connectionId"
      :connections="connections"
      :category="category"
      class="side"
    />
    <Records
      class="content"
      :loading="loading"
      :records="jobs"
      :category="category"
      :connection-id="connectionId"
      @reset="onReset()"
      @sync="onReset()"
    />
  </div>
</template>

<script>
import { getJobs } from "@/api/connections";
import { string } from "@/utils/props-validator";
import sessions from "@/views/connections/utils/sessions";
import polling from "@/utils/polling";
import { Types } from "../../../models/connectors";
import Connections from "./Connections";
import Records from "./Records";

export default {
  components: { Connections, Records },
  props: {
    connections: {
      type: Array,
      required: true
    },
    category: string.enum(Types.SOURCE, Types.DESTINATION)
  },
  data() {
    // 概览中点击连接的相应按钮后，会在 session 中记录 ID
    return {
      jobs: [],
      connectionId: sessions.connectionId(),
      loading: false
    };
  },
  created() {
    this.polling = polling(this.getJobs);
  },
  destroyed() {
    this.polling.abort();
  },
  methods: {
    async getJobs(times) {
      try {
        // 仅在初次轮询时显示加载动画
        this.loading = times === 0;
        const jobRequests = this.connections.map(item =>
          getJobs(item.id).then(({ data }) => {
            for (const job of data) {
              // 用于 <Records /> 组件筛选记录
              job.connectionId = item.id;
            }
            return data;
          })
        );
        const jobResults = await Promise.all(jobRequests);
        const jobs = [].concat(...jobResults);
        this.jobs = jobs.sort((record1, record2) => {
          const d1 = record1.job.createdAt;
          const d2 = record2.job.createdAt;
          if (d1 > d2) {
            return -1;
          }
          if (d1 < d2) {
            return 1;
          }
          return 0;
        });
      } finally {
        this.loading = false;
      }
    },
    onReset() {
      this.polling.start();
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.wrapper {
  display: flex;
  border: @border;
  border-radius: @radius;
  overflow: hidden;
  min-height: 620px;
  .side {
    width: 280px;
    flex-shrink: 0;
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  .content {
    width: 0;
    flex: 1 1 0;
  }
}
</style>
