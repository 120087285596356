<template>
  <Draggable
    v-if="transforms.length"
    :list="transforms"
    :animation="200"
    filter=".upload, .placeholder"
    class="wrapper"
    @end="onSort()"
  >
    <Card
      v-for="(item, index) in transforms"
      :key="item.id"
      :project="item"
      :order="index + 1"
      @remove="onRemove(item, index)"
    />
    <Upload @click="onPick()" />
    <!-- 用来多行时左对齐 -->
    <div class="placeholder"></div>
    <div class="placeholder"></div>
  </Draggable>
  <a-empty
    v-else
    description=""
    :image="EmptyImage"
    :image-style="{ height: '280px' }"
  >
    <a-button type="primary" @click="onPick()">选择项目...</a-button>
  </a-empty>
</template>

<script>
import * as API from "@/api/connections";
import Draggable from "vuedraggable";
import Card from "./transform/Card";
import Upload from "./transform/Upload";
import EmptyImage from "@/assets/images/ai_no_task.svg";
import showPicker from "./transform/show-picker";
import projectList from "./transform/project-list";

export default {
  components: { Draggable, Card, Upload },
  props: {
    connector: Object
  },
  data() {
    return {
      EmptyImage,
      transforms: projectList.map(this.connector.transforms)
    };
  },
  created() {
    projectList.sync();
    this._unwatch = this.$watch(
      () => projectList.list,
      () => {
        this.transforms = projectList.map(this.connector.transforms);
      }
    );
  },
  beforeDestroy() {
    this._unwatch();
  },
  watch: {
    connector(newObj, oldObj) {
      newObj.transforms = oldObj.transforms || [];
    }
  },
  methods: {
    onSort() {
      this.sync();
    },
    onRemove(item, index) {
      this.transforms.splice(index, 1);
      this.sync();
    },
    async onPick() {
      try {
        const transform = await showPicker();
        this.transforms.push(transform);
      } finally {
        // 无论 showPicker 是 resolved 还是 rejected，都应重新 map
        // projectList 中的 item，因为 picker modal 中可能会移除某些 transform
        this.transforms = projectList.map(this.transforms);
        this.sync();
      }
    },
    sync() {
      const connectorId = this.connector.id;
      const transformIds = this.transforms.map(item => item.id);
      this.connector.transforms = transformIds;
      return API.setTransformForConnector({ connectorId, transformIds });
    }
  }
};
</script>

<style lang="less" scoped>
@import "./transform/vars.less";

.wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
  .dbt {
    flex-shrink: 0;
    margin-bottom: 60px;
  }
  .placeholder {
    width: @width;
  }
}
</style>
