<script>
import ImageEmpty from "@/assets/images/ai_add.svg";
import History from "@/views/connections/components/connection-history";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  props: {
    connector: {
      required: true,
      type: Object
    }
  },
  render() {
    const connector = this.connector;
    const connections = connectorList.getConnectionsOfConnector(connector.id);
    if (!connections.length) {
      return <a-empty image={ImageEmpty} imageStyle={{ height: "220px" }} />;
    }
    return <History category={connector.category} connections={connections} />;
  }
};
</script>
