export function selectZIPFile() {
  return new Promise((resolve, reject) => {
    const elm = document.createElement("input");
    elm.type = "file";
    elm.accept = ".zip";
    elm.onchange = evt => {
      const file = elm.files[0];
      if (file) {
        resolve(file);
      } else {
        reject(evt);
      }
    };
    elm.onerror = reject;
    elm.click();
  });
}
