<template>
  <transition name="ndl-fade">
    <div class="dbt">
      <div class="cover"></div>
      <Field
        v-model="project.name"
        :editable="editable"
        class="title"
        @change="$emit('change')"
      />
      <Field
        v-model="project.description"
        multi-rows
        allow-empty
        :editable="editable"
        empty-text="无描述信息"
        class="description"
        @change="$emit('change')"
      />
      <!-- 右上角按钮 -->
      <div v-if="editable" class="btn-wrapper">
        <a-tooltip title="添加">
          <i class="icon-btn ndl-icon-plus" @click="$emit('select')"></i>
        </a-tooltip>
        <a-popconfirm
          title="确认删除此项目？"
          ok-text="确定"
          cancel-text="取消"
          placement="bottomRight"
          @confirm="$emit('remove')"
        >
          <a-tooltip title="删除">
            <i class="icon-btn ndl-icon-x"></i>
          </a-tooltip>
        </a-popconfirm>
      </div>
      <div v-else class="btn-wrapper">
        <a-tooltip title="移除">
          <i class="icon-btn ndl-icon-x" @click="$emit('remove')"></i>
        </a-tooltip>
      </div>
      <span v-if="!editable" class="order">{{ order }}</span>
    </div>
  </transition>
</template>

<script>
import Field from "./CardField";

export default {
  components: { Field },
  props: {
    project: Object,
    buttonText: {
      type: String,
      default: "移除"
    },
    order: {
      type: Number,
      default: 1
    },
    buttonLight: Boolean,
    // 自定义 card 内容，方便应用相同的容器宽高
    customize: Boolean,
    editable: Boolean
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.dbt {
  .common();
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.08);
  background: #fff;
  .cover {
    flex-shrink: 0;
    background: url("./cover.png") center / cover no-repeat;
    height: 50%;
    transition: transform 0.3s;
  }
  &:hover .cover {
    transform: scale(1.03);
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 32px;
    height: 40px;
    padding: 4px 12px;
    background: inherit;
    // z-index 防止 cover 缩放后遮住标题
    z-index: 1;
  }
  .description {
    flex: 1 1 0;
    overflow: hidden;
    padding: 0 12px;
    white-space: pre-wrap;
  }
  .btn-wrapper {
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;
    opacity: 0;
  }
  &:hover .btn-wrapper {
    opacity: 1;
  }
  .order,
  .icon-btn {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.12);
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(3px);
  }
  .icon-btn {
    cursor: pointer;
    transition: all 0.3s;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.25);
    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .order {
    position: absolute;
    top: 10px;
    left: 10px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
