<script>
export default {
  props: {
    showBack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { search: "" };
  },
  watch: {
    search() {
      this.$emit("search", this.search);
    }
  },
  render() {
    const button = this.showBack ? (
      <a-button
        icon="arrow-left"
        type="link"
        style="margin-right: 10px"
        onClick={() => this.$emit("back")}
      />
    ) : null;
    return (
      <div class="ndl-flex ndl-flex--middle ndl-margin-bottom-lg">
        <div class="ndl-flex-item--grow ndl-flex ndl-flex--middle">
          {button}
          <h1>{this.$slots.default}</h1>
        </div>
        <a-input-search
          value={this.search}
          onInput={evt => (this.search = evt.target.value)}
          placeholder="查找"
          style="width: 220px"
        />
      </div>
    );
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

h1 {
  color: @local-title-color;
  font-size: @local-font-size-lg;
  margin-bottom: 0;
}
</style>
