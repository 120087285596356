<template>
  <div class="header">
    <span class="cell-checkbox">
      <Checkbox :checked="allChecked" @change="$listeners.check" />
    </span>
    <span class="cell-title">源表名</span>
    <span class="cell-title">目标表名</span>
    <span class="cell-syncmode">同步模式</span>
    <span class="cell-primarykey">主键</span>
    <span class="cell-cursorfield">
      <span style="padding-right: 4px">同步标识</span>
      <a-tooltip
        title="追加或更新数据时，根据该字段判断是否需要同步某条记录（追加模式下，该字段为空则全部追加）"
      >
        <i class="ndl-icon-help-circle"></i>
      </a-tooltip>
    </span>
    <span class="cell-arrow"></span>
  </div>
</template>

<script>
import Checkbox from "@/components/checkbox";

export default {
  components: { Checkbox },
  props: {
    allChecked: Boolean
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @text-color-secondary;
  margin-top: @margin * 2;
  .cell-checkbox {
    .base-checkbox();
  }
  .cell-title {
    .base-title();
  }
  .cell-syncmode {
    .base-syncmode();
  }
  .cell-primarykey {
    .base-primarykey();
  }
  .cell-cursorfield {
    .base-cursorfield();
    display: flex;
    align-items: center;
  }
  .cell-arrow {
    .base-arrow();
  }
}
</style>
