<template>
  <a-form layout="vertical">
    <SyncFrequency v-model="connection.schedule" />
    <a-form-item label="目标表前缀">
      <a-input v-model="connection.prefix" placeholder="请输入前缀" />
    </a-form-item>
    <SyncCatalog :connection="connection" />
  </a-form>
</template>

<script>
import connectorList from "@/views/connections/models/connectors";
import sessions from "@/views/connections/utils/sessions";
import SyncFrequency from "@/views/connections/components/job-frequency";
import SyncCatalog from "./SyncCatalog";

export default {
  components: { SyncFrequency, SyncCatalog },
  props: {
    connection: {
      type: Object,
      required: true
    },
    notNavigation: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.validate();
  },
  watch: {
    "connection.schedule"(newVal, oldVal) {
      // 从手动同步修改为自动同步，或自动同步修改为手动同步时，自动
      if (Boolean(newVal) !== Boolean(oldVal)) {
        this.connection.status = newVal ? "active" : "inactive";
      }
    }
  },
  methods: {
    async next() {
      await connectorList.saveConnection(this.connection);
      if (this.notNavigation) {
        return;
      }
      // eslint-disable-next-line prettier/prettier
      const gotoConnectorId = sessions.fromConnector() || this.connection.sourceId;
      if (gotoConnectorId) {
        this.$router.push({
          name: "connector-details",
          params: { connectorId: gotoConnectorId }
        });
      } else {
        this.$router.push({ name: "connections-overview" });
      }
    },
    async prev() {
      this.connection.destinationId = null;
    },
    validate() {
      this.$emit("validate", true);
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form {
  margin: 80px auto;
  max-width: 1000px;
  /deep/ .ant-form-item {
    margin-bottom: 42px;
  }
}
</style>
