<template>
  <svg xmlns="http://www.w3.org/2000/svg" stroke-width="3" fill="none">
    <defs>
      <linearGradient id="blue">
        <!-- @blue-5 -->
        <stop offset="0" stop-color="#40a9ff" />
        <!-- @blue-3 -->
        <stop offset="0.5" stop-color="#91d5ff" />
        <!-- @blue-5 -->
        <stop offset="1" stop-color="#40a9ff" />
      </linearGradient>
      <linearGradient id="red">
        <!-- @red-5 -->
        <stop offset="0" stop-color="#ff4d4f" />
        <!-- @red-3 -->
        <stop offset="0.5" stop-color="#ffa39e" />
        <!-- @red-5 -->
        <stop offset="1" stop-color="#ff4d4f" />
      </linearGradient>
      <linearGradient id="green">
        <!-- @green-5 -->
        <stop offset="0" stop-color="#73d13d" />
        <!-- @green-3 -->
        <stop offset="0.5" stop-color="#b7eb8f" />
        <!-- @green-5 -->
        <stop offset="1" stop-color="#73d13d" />
      </linearGradient>
      <linearGradient id="gray">
        <!-- @gray-6 -->
        <stop offset="0" stop-color="#bfbfbf" />
        <!-- @gray-5 -->
        <stop offset="0.5" stop-color="#d9d9d9" />
        <!-- @gray-6 -->
        <stop offset="1" stop-color="#bfbfbf" />
      </linearGradient>
    </defs>
    <path
      v-for="(item, index) in paths"
      :key="index"
      :d="pathof(item.from, item.to)"
      :stroke="item.color"
      :class="connectionActivity[item.id] ? 'active' : 'inactive'"
      @click="onPathClick(item)"
    />
  </svg>
</template>

<script>
import debounce from "lodash/debounce";

function colorOfStatus(status) {
  switch (status) {
    case "succeeded":
      return "url(#green)";
    case "running":
      return "url(#blue)";
    case "failed":
      return "url(#red)";
    default:
      return "url(#gray)";
  }
}

export default {
  props: {
    connections: Array,
    dragging: Boolean,
    // 获取 connector container
    getContainer: Function,
    selector: {
      type: String,
      default: ".group-item[data-connector-id]"
    }
  },
  data() {
    return { paths: [] };
  },
  computed: {
    connectionActivity() {
      const connections = this.connections || [];
      return connections.reduce((map, item) => {
        map[item.id] = !!item.isActive;
        return map;
      }, {});
    }
  },
  mounted() {
    const el = this.getContainer();
    if (el) {
      const callback = debounce(this.remake, 300, { leading: true });
      const observer = new ResizeObserver(callback);
      observer.observe(el);
      this._observer = observer;
    }
  },
  destroyed() {
    if (this._observer) {
      this._observer.disconnect();
    }
  },
  watch: {
    connections: {
      deep: true,
      handler() {
        this.updateStatus();
      }
    },
    dragging() {
      this.remake();
    }
  },
  methods: {
    remake() {
      const root = this.getContainer();
      // 每个节点分别生成输入、输出 anchor 位置信息
      // 又因 path 是直线时无法应用渐变效果，这里给所有输入 anchor 的 y 坐标 +1
      // 原因见 linearGradient 属性 gradientUnits
      const nodes = Array.from(root.querySelectorAll(this.selector)).map(el => {
        const id = el.dataset.connectorId || null;
        const input = [el.offsetLeft, el.offsetTop + 1 + el.offsetHeight / 2];
        const output = [
          el.offsetLeft + el.offsetWidth,
          el.offsetTop + el.offsetHeight / 2
        ];
        return [id + "", { input, output }];
      });
      const nodeMap = new Map(nodes);
      const connections = this.connections || [];
      const paths = [];
      for (const item of connections) {
        const sourceNode = nodeMap.get(item.sourceId);
        const destNode = nodeMap.get(item.destinationId);
        if (sourceNode && destNode) {
          paths.push({
            id: item.id,
            color: colorOfStatus(item.latestSyncJobStatus),
            from: sourceNode.output,
            to: destNode.input,
            connection: item
          });
        }
      }
      this.paths = paths;
    },
    updateStatus() {
      const connectionMap = this.connections.reduce((map, item) => {
        map[item.id] = colorOfStatus(item.latestSyncJobStatus);
        return map;
      }, {});
      for (const item of this.paths) {
        if (connectionMap[item.id]) {
          item.color = connectionMap[item.id];
        }
      }
    },
    /**
     * 绘制贝塞尔曲线
     * C 起点控制点x 起点控制点y 终点控制点x 终点控制点y 终点x 终点y
     * 起点和起点控制点的斜率 k1，终点和终点控制点的斜率 k2
     * k1 渐变到 k2 就得到了起点到终点之间各点的斜率，因此得到平滑的贝塞尔曲线。
     * 这里通过中点坐标生成控制点，曲线会较为对称、平滑
     */
    pathof(from, to) {
      const mid = [
        from[0] + (to[0] - from[0]) / 2,
        from[1] + (to[1] - from[1]) / 2
      ];
      return `M ${from[0]} ${from[1]} C ${mid[0]} ${from[1]} ${mid[0]} ${to[1]} ${to[0]} ${to[1]}`;
    },
    onPathClick(path) {
      this.$emit("select", path.connection);
    }
  }
};
</script>

<style lang="less" scoped>
path {
  opacity: 1;
  transition: all 0.2s;
  cursor: pointer;
  &.inactive {
    opacity: 0;
  }
  &:hover {
    stroke-width: 5;
  }
}
</style>
