<template>
  <div v-on="$listeners" :class="['upload', { loading }]">
    <span class="desc">{{ loading ? "正在上传..." : description }}</span>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    description: {
      type: String,
      default: "上传项目"
    }
  },
  methods: {
    onClick() {
      if (!this.loading) {
        this.$emit("click");
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.upload {
  .common();
  position: relative;
  border: dashed 1px #ebebeb;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background: url(~@/assets/images/ai_upload.svg) center / contain no-repeat;
  &:hover {
    border-color: #1890ff;
  }
  &.loading {
    background: url("/images/loading-buffering.gif") center / 26px no-repeat;
    cursor: progress;
  }
  .desc {
    color: rgba(0, 0, 0, 0.45);
    position: absolute;
    bottom: 2em;
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
