<template>
  <Skeleton :title="title">
    <!-- header slots -->
    <img slot="header-left" :src="definitionIcon" class="icon" />
    <a slot="header-right" class="ndl-flex ndl-flex--middle">
      <span class="ndl-margin-right-sm">查看文档</span>
      <i class="ndl-icon-book-open"></i>
    </a>
    <!-- footer -->
    <template slot="footer-right">
      <a-button @click="onCancel()">取消</a-button>
      <a-button type="primary" :loading="loading" @click="onConfirm()">
        创建
      </a-button>
    </template>

    <!-- body -->
    <ConnectorEditor
      v-model="connector"
      ref="editor"
      :definition-id="definitionId"
    />
  </Skeleton>
</template>

<script>
import Skeleton from "@/views/connections/components/common-skeleton";
import ConnectorEditor from "@/views/connections/components/connector-editor";
import { definitionList, localeTypes, Types } from "./models/definitions";
import { connectorList } from "./models/connectors";

export default {
  components: { Skeleton, ConnectorEditor },
  data() {
    return {
      connector: {},
      loading: false
    };
  },
  computed: {
    definitionId() {
      return this.$route.params.definitionId;
    },
    definition() {
      return definitionList.getDefinition(this.definitionId) || {};
    },
    category() {
      return this.definition.category || Types.SOURCE;
    },
    definitionIcon() {
      return definitionList.getIcon(this.definitionId);
    },
    title() {
      return `添加「${this.definition.name}」${localeTypes(this.category)}`;
    }
  },
  methods: {
    onCancel() {
      this.$router.replace({
        name: "definition-directory",
        params: { category: this.category }
      });
    },
    async onConfirm() {
      if (!this.$refs.editor.validate()) {
        return;
      }
      try {
        this.loading = true;
        const connector = await connectorList.saveConnector({
          ...this.connector,
          category: this.category,
          definitionId: this.definitionId
        });
        this.$router.push({
          name: "connector-details",
          params: { connectorId: connector.id }
        });
      } catch (err) {
        const resData = err.data || {};
        this.$notification.error({
          message: "操作失败",
          description: resData.msg
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.icon {
  height: 30px;
  max-width: 85px;
  margin-right: 20px;
}
</style>
