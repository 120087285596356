import { render, staticRenderFns } from "./Contextify.vue?vue&type=template&id=8d60fd60&scoped=true&"
import script from "./Contextify.vue?vue&type=script&lang=js&"
export * from "./Contextify.vue?vue&type=script&lang=js&"
import style0 from "./Contextify.vue?vue&type=style&index=0&id=8d60fd60&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d60fd60",
  null
  
)

export default component.exports