<template>
  <div class="wrapper">
    <div :class="['stream', { expanded }]">
      <span class="cell-checkbox">
        <Checkbox v-model="config.selected" />
      </span>
      <span class="cell-title highlight">
        <Name :name="stream.name" :alias="stream.jsonSchema.title" />
      </span>
      <span class="cell-title highlight">
        <StreamName
          v-model="config.destinationName"
          :default-name="`${streamNamePrefix || ''}${stream.name}`"
        />
      </span>
      <span class="cell-syncmode">
        <SyncMode
          :destination-modes="destinationModes"
          :config="config"
          :stream="stream"
        />
      </span>
      <span class="cell-primarykey">
        <SyncKeys
          v-if="showPrimaryKey"
          color="cyan"
          :keys="config.primaryKey"
          @select="toggle()"
        />
      </span>
      <span class="cell-cursorfield">
        <SyncKeys
          v-if="showCursorField"
          color="blue"
          :keys="config.cursorField"
          @select="toggle()"
        />
      </span>
      <span class="cell-arrow" @click="toggle()">
        <i class="ndl-icon-chevron-right"></i>
      </span>
    </div>
    <Fields
      v-if="expanded"
      :config="config"
      :properties="stream.jsonSchema.properties"
    />
  </div>
</template>

<script>
import Checkbox from "@/components/checkbox";
import Name from "./Name";
import StreamName from "./StreamName";
import SyncMode from "./SyncMode";
import SyncKeys from "./SyncKeys";
import Fields from "./Fields";

export default {
  components: {
    Checkbox,
    Name,
    StreamName,
    SyncMode,
    SyncKeys,
    Fields
  },
  props: {
    source: Object,
    streamNamePrefix: String,
    destinationModes: Array
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    stream() {
      return this.source.stream;
    },
    config() {
      return this.source.config;
    },
    showPrimaryKey() {
      return this.config.destinationSyncMode === "append_dedup";
    },
    showCursorField() {
      return this.config.syncMode === "incremental";
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.wrapper {
  background: @background;
  border-radius: @border-radius;
  margin: @margin 0;
  // overflow 创建块级格式上下文，以取消外边距重叠问题
  overflow: hidden;
  .stream {
    height: @stream-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cell-checkbox {
    .base-checkbox();
  }
  .cell-title {
    .base-title();
  }
  .cell-syncmode {
    .base-syncmode();
  }
  .cell-primarykey {
    .base-primarykey();
  }
  .cell-cursorfield {
    .base-cursorfield();
  }
  .cell-arrow {
    .base-arrow();
    transition: transform 0.2s;
    cursor: pointer;
  }
  .expanded > .cell-arrow {
    transform: rotate(90deg);
  }
  .highlight {
    color: @local-title-color;
  }
  .shallow {
    color: @text-color-secondary;
  }
}
</style>
