<template>
  <span class="name-component">
    <span class="title" :title="name">{{ name }}</span>
    <span class="title sub-title" :title="alias">{{ alias }}</span>
  </span>
</template>

<script>
export default {
  props: {
    name: String,
    alias: String
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.name-component {
  .base-flex-column();
}
.title {
  .base-ellipsis();
}
.sub-title {
  font-size: 13px;
  font-style: italic;
  color: @blue-6;
}
</style>
