<template>
  <!-- .ndl-antd 全局类里面的所有 antd 表单控件样式都被重写 -->
  <section class="skeleton ndl-antd">
    <div class="header">
      <slot name="header-left"></slot>
      <span v-if="title" class="title">{{ title }}</span>
      <span class="placeholder"></span>
      <slot name="header-right"></slot>
    </div>
    <div ref="body" class="body">
      <slot></slot>
    </div>
    <div class="footer">
      <slot name="footer-left"></slot>
      <span class="placeholder"></span>
      <slot name="footer-right"></slot>
    </div>
  </section>
</template>

<script>
export default {
  provide() {
    const vm = this;
    return {
      getSkeletonBody() {
        return vm.$refs.body;
      }
    };
  },
  props: {
    title: String
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.skeleton {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header,
.footer {
  flex-shrink: 0;
  height: 72px;
  padding: 0 32px;
  background: #fafafa;
  display: flex;
  align-items: center;
}
.title {
  font-size: 20px;
  color: @local-title-color;
}
.placeholder {
  flex: 1 1 0;
}
.footer /deep/ .ant-btn {
  margin-left: 16px;
  min-width: 80px;
  &[disabled] {
    color: #fff;
    border-color: #1890ff;
    background: #1890ff;
    opacity: 0.6;
  }
}

.body {
  flex: 1 1 0;
  overflow: auto;
}
</style>
