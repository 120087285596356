// 支持的时间单位
export const TimeUnits = {
  minutes: "minutes",
  hours: "hours",
  days: "days",
  weeks: "weeks",
  months: "months"
};

// 时间单位对应的中文名称
export function localeTimeUnits(timeUnit) {
  switch (timeUnit) {
    case TimeUnits.minutes:
      return "分钟";
    case TimeUnits.hours:
      return "小时";
    case TimeUnits.days:
      return "天";
    case TimeUnits.weeks:
      return "周";
    case TimeUnits.months:
      return "月";
    default:
      return "";
  }
}

export function localeSyncFreq(schedule) {
  const { units, timeUnit } = schedule || {};
  // schedule 为 null 或 units 为空时，视为手动(manual)同步
  if (!units) {
    return "手动同步";
  }
  const timeUnitLable = localeTimeUnits(timeUnit);
  switch (timeUnit) {
    case TimeUnits.minutes:
    case TimeUnits.hours:
      return `每 ${units} ${timeUnitLable}`;
    case TimeUnits.days:
    case TimeUnits.weeks:
    case TimeUnits.months:
      return units > 1 ? `每 ${units} ${timeUnitLable}` : `每${timeUnitLable}`;
    default:
      return "";
  }
}

export function normalize(schedule) {
  if (!schedule || !schedule.units) {
    return null;
  }
  return {
    units: String(schedule.units),
    timeUnit: schedule.timeUnit || TimeUnits.minutes
  };
}

export default localeSyncFreq;
