<template>
  <div class="header">
    <span class="title"><a-icon type="history" />同步历史</span>
    <span v-if="connectionId">
      <a-button type="danger" size="small" icon="stop" @click="onReset()">
        重置数据
      </a-button>
      <a-button
        v-if="connection.isSyncing"
        type="danger"
        size="small"
        icon="minus-circle"
        :loading="stopping"
        @click="onStop()"
      >
        {{ stopping ? "正在取消" : "取消同步" }}
      </a-button>
      <a-button
        type="primary"
        size="small"
        icon="sync"
        :loading="running || connection.isSyncing"
        @click="onSync()"
      >
        {{ running || connection.isSyncing ? "正在同步" : "立即同步" }}
      </a-button>
    </span>
  </div>
</template>

<script>
import connectorList from "@/views/connections/models/connectors";
import * as APIJob from "@/api/connections";

export default {
  props: {
    connectionId: [String, Number]
  },
  data() {
    return {
      running: false,
      stopping: false
    };
  },
  computed: {
    connection() {
      return connectorList.getConnection(this.connectionId) || {};
    }
  },
  watch: {
    "connection.isSyncing"() {
      this.running = false;
      this.stopping = false;
    }
  },
  methods: {
    onReset() {
      this.$confirm({
        title: "操作确认",
        content: `确定要重置此连接吗？`,
        okType: "danger",
        onOk: async () => {
          await APIJob.resetConnection(this.connectionId);
          this.$emit("reset");
        }
      });
    },
    async onStop() {
      try {
        this.stopping = true;
        await APIJob.stopJob(this.connectionId);
        // 触发父组件更新任务列表
        this.$emit("sync");
      } catch {
        this.stopping = false;
        this.$message.error("取消失败");
      }
    },
    async onSync() {
      try {
        this.running = true;
        await APIJob.runJob(this.connectionId);
        this.$emit("sync");
      } catch (err) {
        this.running = false;
        this.$message.error("同步失败");
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: @row-height;
  padding: 0 22px;
  color: @local-title-color;
  font-weight: bold;

  .anticon {
    margin-right: 10px;
  }
  .ant-btn {
    margin-left: @gutter;
  }
}
</style>
