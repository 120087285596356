<template>
  <div :class="['status', status]">
    <JobStatus :status="status" class="status-icon" />
    <div class="content">
      <span class="title">
        <slot name="title" :status="status | localeStatus">
          {{ status | localeStatus }}
        </slot>
      </span>
      <span v-if="showDescription" class="desc" :title="jobinfo">
        {{ jobinfo }}
      </span>
    </div>
  </div>
</template>

<script>
import JobStatus from "@/views/connections/components/job-status";
import { localeStatus } from "@/views/connections/components/job-status";
import * as helpers from "@/views/connections/utils/helpers";

export default {
  components: { JobStatus },
  props: {
    record: Object,
    showDescription: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    status() {
      const job = this.record.job || {};
      return job.status;
    },
    jobinfo() {
      return helpers.jobinfo(this.record);
    }
  },
  filters: { localeStatus }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.status {
  display: flex;
  align-items: center;
  .status-icon {
    flex-shrink: 0;
  }
  .content {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &.failed .title {
    color: @red-6;
  }
  .desc {
    color: @text-color-secondary;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
