var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"context-motion"},on:{"after-leave":function($event){return _vm.afterLeave()}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"},{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.hide,
      middleware: _vm.contextConfig.middleware || _vm.defaultMiddleware,
      events: _vm.contextConfig.events || ['touchstart', 'mousedown', 'keydown']
    }),expression:"{\n      handler: hide,\n      middleware: contextConfig.middleware || defaultMiddleware,\n      events: contextConfig.events || ['touchstart', 'mousedown', 'keydown']\n    }"}],class:[
      'contextify-container',
      _vm.contextConfig.class,
      {
        light: _vm.contextConfig.light,
        'backdrop-filter': _vm.isBackdropFilterSurport,
        'keep-on-hide': _vm.contextConfig.keepOnHide
      }
    ],style:([_vm.style, _vm.contextConfig.style]),attrs:{"placement":_vm.placement}},[_vm._t("default",function(){return [_c('ContentComponent',_vm._g(_vm._b({attrs:{"hide-context":_vm.hide},on:{"~hook:mounted":function($event){return _vm.onMounted()}}},'ContentComponent',_vm.config,false),_vm.config.on))]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }