<template>
  <section>
    <!-- 面包屑导航条 -->
    <Header :connector="connector" />
    <a-skeleton v-if="loading" active style="margin: 60px 20px" />
    <template v-else>
      <!-- 连接器基本信息 -->
      <Cover :connector="connector" />
      <!-- 菜单标签页 -->
      <Tabs :enable-transform="connector.category == 'source'">
        <Overview slot="overview" :connector="connector" />
        <History slot="history" :connector="connector" />
        <Setting slot="setting" :connector="connector" />
        <Transform slot="transform" :connector="connector" />
      </Tabs>
    </template>
  </section>
</template>

<script>
import Header from "./Header";
import Cover from "./Cover";
import Tabs from "./Tabs";
import Overview from "./Overview";
import History from "./History";
import Setting from "./Setting";
import Transform from "./Transform";
import { connectorList, localeTypes } from "../../../models/connectors";

export default {
  components: { Cover, Header, Tabs, Overview, History, Setting, Transform },
  props: {
    connectorId: [String, Number]
  },
  computed: {
    connector() {
      return connectorList.getConnector(this.connectorId) || {};
    },
    loading() {
      return connectorList.loading;
    }
  },
  filters: { localeTypes }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

section {
  padding: @local-padding;
}
</style>
