<template>
  <Row class="list-item" @click="$parent.$emit('select', item)">
    <!-- 图标及名称 -->
    <Cell type="info">
      <ConnectorCard :connector-id="item[idProp]" />
    </Cell>
    <!-- 同步频率 -->
    <Cell type="frequence">{{ item.schedule | localeFreq }}</Cell>
    <!-- 状态 -->
    <Cell type="status">
      <JobStatus :status="item.latestSyncJobStatus" />
    </Cell>
    <!-- 最近同步时间 -->
    <Cell type="latest">
      <template v-if="item.latestSyncJobCreatedAt">
        {{ item.latestSyncJobCreatedAt }}
      </template>
      <i v-else class="color-disabled">未同步</i>
    </Cell>
    <!-- 启用 / 禁用 -->
    <Cell type="switch">
      <a-switch
        v-if="item.schedule"
        :loading="item.isSwitching"
        :checked="item.status === 'active'"
        @change="onActiveChange(item, $event)"
        @click.native.stop
      />
    </Cell>
    <!-- 操作 -->
    <Cell type="actions">
      <a-tooltip title="删除连接">
        <i
          class="danger ndl-icon-x-circle"
          @click.stop="$parent.$emit('delete', item)"
        ></i>
      </a-tooltip>
      <a-tooltip title="连接设置">
        <i
          class="ndl-icon-settings"
          @click.stop="$parent.$emit('setting', item)"
        ></i>
      </a-tooltip>
      <!-- 取消同步任务 -->
      <a-tooltip v-if="stopping" title="正在取消">
        <a-icon type="loading" class="danger" />
      </a-tooltip>
      <a-tooltip v-else-if="item.isSyncing" title="取消同步">
        <i class="danger ndl-icon-minus-circle" @click.stop="onStop()"></i>
      </a-tooltip>
      <!-- 立即同步 -->
      <a-tooltip v-else-if="running" title="正在同步">
        <a-icon type="loading" />
      </a-tooltip>
      <a-tooltip v-else title="立即同步">
        <i class="ndl-icon-refresh-cw" @click.stop="onSync()"></i>
      </a-tooltip>
      <!-- 同步历史 -->
      <a-tooltip title="同步历史">
        <i
          class="ndl-icon-history-line"
          @click.stop="$parent.$emit('history', item)"
        ></i>
      </a-tooltip>
      <a-tooltip :title="`查看${categoryLabel}`">
        <i
          class="ndl-icon-chevron-right"
          @click.stop="$parent.$emit('visit', item)"
        ></i>
      </a-tooltip>
    </Cell>
  </Row>
</template>

<script>
import ConnectorCard from "@/views/connections/components/connector-card";
import JobStatus from "@/views/connections/components/job-status";
import localeFreq from "@/views/connections/utils/sync-frequency";
import Row from "./Row";
import Cell from "./Cell";

export default {
  components: { ConnectorCard, JobStatus, Row, Cell },
  props: {
    item: Object,
    idProp: String,
    categoryLabel: String
  },
  data() {
    return {
      running: false,
      stopping: false
    };
  },
  filters: { localeFreq },
  watch: {
    // 根据连接的 isSyncing 状态判断是否正在同步/已取消同步
    "item.isSyncing"() {
      this.stopping = false;
      this.running = false;
    }
  },
  methods: {
    onActiveChange(connection, bool) {
      this.$parent.$emit(bool ? "enable" : "disable", connection);
    },
    onStop() {
      this.stopping = true;
      const onFailed = () => {
        this.stopping = false;
      };
      this.$parent.$emit("stop", this.item, onFailed);
    },
    onSync() {
      this.running = true;
      const onFailed = () => {
        this.running = false;
      };
      this.$parent.$emit("sync", this.item, onFailed);
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

@row-height: 80px;

.list-item {
  height: @row-height;
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background: @blue-1;
  }
  .color-disabled {
    color: fade(#000, 25%);
  }
  .cell.info .card {
    width: 100%;
  }
  .cell.info .card /deep/ img {
    width: 45px;
    height: 45px;
    margin: 0 16px;
  }
  .cell.info .card /deep/ .content {
    padding-left: 0;
  }
  .cell.actions {
    .danger {
      color: @red-5 !important;
    }
    i {
      padding: 3px;
      margin-right: 12px;
    }
    i:hover {
      color: @blue-6;
    }
  }
}
</style>
