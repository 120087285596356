<template>
  <section>
    <ConnectorGroup
      :loading="loading"
      :title="Types.SOURCE | localeTypes"
      :connectors="sources"
      @add="addConnector(Types.SOURCE)"
      @select="onSelectConnector"
      @active="onActiveConnector"
      @dragstart="onDragStart"
      @dragend="onDragEnd"
      @sort="onSort"
    />
    <div class="gutter-placeholder"></div>
    <ConnectorGroup
      :loading="loading"
      :title="Types.DESTINATION | localeTypes"
      :connectors="destinations"
      @add="addConnector(Types.DESTINATION)"
      @select="onSelectConnector"
      @active="onActiveConnector"
      @dragstart="onDragStart"
      @dragend="onDragEnd"
      @sort="onSort"
    />
    <ConnectorGuideLine
      class="guide-line"
      :connections="connections"
      :dragging="dragging"
      :get-container="() => $el"
      @select="onSelectConnection"
    />
    <ConnectionStatusBar />
    <ModeSwitch />
  </section>
</template>

<script>
import ModeSwitch from "./components/mode-switch";
import ConnectorGroup from "./components/connector-group";
import ConnectorGuideLine from "./components/connector-guideline";
import ConnectionStatusBar from "./components/connection-statusbar";
import { Types, localeTypes } from "./models/definitions";
import connectors from "./models/connectors";

export default {
  components: {
    ModeSwitch,
    ConnectorGroup,
    ConnectorGuideLine,
    ConnectionStatusBar
  },
  data() {
    return { Types };
  },
  computed: {
    loading() {
      return connectors.loading;
    },
    dragging() {
      return connectors.dragging;
    },
    sources() {
      return connectors.sources;
    },
    destinations() {
      return connectors.destinations;
    },
    connections() {
      return connectors.connections;
    }
  },
  filters: { localeTypes },
  destroyed() {
    // 重置连接器状态
    connectors.activeConnector(null);
  },
  methods: {
    addConnector(category) {
      this.$router.push({
        name: "definition-directory",
        params: { category }
      });
    },
    onSelectConnector(connector) {
      this.$router.push({
        name: "connector-details",
        params: { connectorId: connector.id }
      });
    },
    onActiveConnector(connectorId) {
      connectors.activeConnector(connectorId);
    },
    onSelectConnection(connection) {
      this.$router.push({
        name: "connection-editor",
        params: { connectionId: connection.id }
      });
    },
    onDragStart() {
      connectors.setDragging(true);
    },
    onDragEnd() {
      connectors.setDragging(false);
    },
    onSort(newConnectors) {
      connectors.sortConnector(newConnectors);
    }
  }
};
</script>

<style lang="less" scoped>
@import "./assets/vars.less";

section {
  .section();
  display: flex;
  // relative 定位可使 .group-item 的 offsetParent 变成 section，勿取消该样式
  position: relative;
}
.group {
  flex: 1 1 0;
  z-index: 2;
}
.gutter-placeholder {
  min-width: 100px;
  flex: 1 1 0;
  z-index: -1;
}
.guide-line {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>
