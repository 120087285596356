<script>
import Skeleton from "./components/common-skeleton";
import Editor from "./components/connection-editor";
import normalize from "./utils/normalize-connection";
import connectorList from "./models/connectors";

export default {
  data() {
    return {
      connection: {}
    };
  },
  async created() {
    await connectorList.init();
    const connectionId = this.$route.params.connectionId;
    this.connection = normalize(connectorList.getConnection(connectionId));
  },
  render() {
    if (connectorList.loading) {
      return (
        <Skeleton title="编辑连接">
          <a-skeleton active style="padding: 30px 60px" />
        </Skeleton>
      );
    }
    return <Editor title="编辑连接" connection={this.connection} />;
  }
};
</script>
