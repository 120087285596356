<template>
  <header>
    <a-button
      icon="arrow-left"
      type="link"
      @click="$router.push({ name: 'connections-overview' })"
    />
    <a-breadcrumb>
      <a-breadcrumb-item>
        {{ connector.category | localeTypes }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ connector.name }}</a-breadcrumb-item>
    </a-breadcrumb>
  </header>
</template>

<script>
import { localeTypes } from "../../../models/connectors";

export default {
  props: {
    connector: {
      required: true,
      type: Object
    }
  },
  filters: { localeTypes }
};
</script>

<style lang="less" scoped>
header {
  display: flex;
  align-items: center;
  button {
    margin-right: 12px;
  }
}
</style>
