<template>
  <div
    :class="['container', showHoverBg ? 'hover-bg' : '']"
    @click="$event => $emit('click', $event)"
  >
    <div class="btn">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showHoverBg: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.container {
  display: inline-block;
  border-radius: 4px;
  padding: 3px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: @local-title-color;
  background-color: #ffffff;

  * + * {
    margin-left: 4px;
  }

  & + & {
    margin-left: 8px;
  }
}
.hover-bg:hover {
  background-color: @local-btn-bg-color;
}
.btn {
  .flex();
  justify-content: center;
}
</style>
