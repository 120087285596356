<template>
  <!-- 注意是 connectorId 不是 connectionId -->
  <Card :connector-id="connectorId" />
</template>

<script>
import Card from "@/views/connections/components/connector-card";
import { connectorList } from "@/views/connections/models/connectors";
import { connectorIdPropOfOppositeType } from "@/views/connections/utils/connector-category";

export default {
  components: { Card },
  props: {
    connectionId: [Number, String],
    category: String
  },
  computed: {
    connectorId() {
      // 根据 connectionId 获取 connection
      const connection = connectorList.getConnection(this.connectionId);
      // 根据当前 connector 的 category 判断是读取 sourceId 还是 destinationId
      const idProp = connectorIdPropOfOppositeType(this.category);
      return connection ? connection[idProp] : null;
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  /deep/ img {
    width: 38px;
    height: 38px;
  }
  /deep/ .title {
    font-size: 14px;
  }
  /deep/ .desc {
    font-size: 12px;
  }
}
</style>
