<template>
  <a-modal
    class="model-content-custom"
    :width="800"
    :footer="null"
    :centered="true"
    :mask-closable="false"
  >
    <ModelHeader slot="title" :img-src="connectorIcon" :title="connectorName" />
    <div class="model-body">
      <Setting :connector="connector" />
    </div>
  </a-modal>
</template>

<script>
import ModelHeader from "@/views/connections/components/modal-common-header";
import { Setting } from "@/views/connections/components/connector-details";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  components: { ModelHeader, Setting },
  props: {
    connector: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    connectorIcon() {
      return connectorList.getConnectorIcon(this.connector.id);
    },
    connectorName() {
      const name = connectorList.getConnectorName(this.connector.id);
      return name + " - 主数据库设置";
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";
</style>
