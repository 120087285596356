<template>
  <a-modal width="80vw" centered :footer="null">
    <Card
      v-for="item in list"
      :key="item.id"
      :project="item"
      button-text="使用"
      button-light
      editable
      @change="onChange(item)"
      @remove="onRemove(item)"
      @select="onSelect(item)"
    />
    <Upload
      description="上传项目 zip 文件"
      :loading="loading"
      @click="onUpload()"
    />
    <!-- 用来多行时左对齐 -->
    <div class="placeholder"></div>
    <div class="placeholder"></div>
  </a-modal>
</template>

<script>
import * as API from "@/api/connections";
import * as utils from "./picker-utils";
import Card from "./Card";
import Upload from "./Upload";
import projectList from "./project-list";

export default {
  components: { Card, Upload },
  props: {
    $resolve: Function
  },
  data() {
    return { loading: false };
  },
  computed: {
    list() {
      return projectList.list;
    }
  },
  mounted() {
    if (!this.list.length) {
      this.onUpload();
    }
  },
  methods: {
    onChange(project) {
      project.sync();
    },
    onRemove(project) {
      projectList.remove(project);
    },
    onSelect(project) {
      this.$resolve(project);
    },
    async onUpload() {
      try {
        const file = await utils.selectZIPFile();
        // 先选择文件再显示 loading 动画，避免无法识别文件选择窗口取消动作问题
        this.loading = true;
        const { data } = await API.uploadTransform(file);
        const project = projectList.push(data);
        this.$resolve(project);
      } catch (err) {
        const errData = (err && err.data) || {};
        this.$message.error(errData.message || errData.error || "上传失败");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.ant-modal-root /deep/ .ant-modal-body {
  height: 90vh;
  margin: auto;
  overflow: auto;
  display: flex;
  justify-content: space-around;
  align-content: flex-start;
  flex-wrap: wrap;
  .dbt,
  .upload,
  .placeholder {
    margin: 20px;
  }
  .placeholder {
    width: @width;
  }
}
</style>
