<template>
  <div class="content" @click="toPage">
    <a-tooltip :title="title">
      <span v-if="isCommon" class="ndl-icon-grid icon"></span>
      <span v-else class="ndl-icon-git-pull-request icon"></span>
    </a-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCommon: false,
      title: ""
    };
  },
  mounted() {
    if (this.$route.name === "connections-overview-easy") {
      this.isCommon = false;
    } else {
      this.isCommon = true;
    }
    this.title = `当前是${
      this.isCommon ? "普通模式" : "主库模式"
    }，点击进行模式切换`;
  },
  methods: {
    toPage() {
      let name = "connections-overview";
      if (this.isCommon) {
        name = "connections-overview-easy";
      }
      this.$router.push({ name: name });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.content {
  .flex();
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  bottom: 10%;
  right: 10%;
  background-color: #fff;
  box-shadow: 0 0 12px #aaa;
  transition: box-shadow 0.3s;
  z-index: 1000;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 20px #aaa;
  }
}
.icon {
  .flex();
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: @local-font-size-lg;
  color: #333;
}
</style>
