import { render, staticRenderFns } from "./SyncKeys.vue?vue&type=template&id=457127c8&scoped=true&"
import script from "./SyncKeys.vue?vue&type=script&lang=js&"
export * from "./SyncKeys.vue?vue&type=script&lang=js&"
import style0 from "./SyncKeys.vue?vue&type=style&index=0&id=457127c8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457127c8",
  null
  
)

export default component.exports