<template>
  <div class="directory">
    <div class="header">
      <a-button icon="arrow-left" type="link" @click="$emit('back')" />
      <span class="title">连接器</span>
    </div>
    <div class="group-label">{{ Types.SOURCE | localeTypes }}</div>
    <div
      :class="['group-item', { active: category === Types.SOURCE }]"
      @click="$emit('change', Types.SOURCE)"
    >
      全部
    </div>
    <div class="group-label">{{ Types.DESTINATION | localeTypes }}</div>
    <div
      :class="['group-item', { active: category === Types.DESTINATION }]"
      @click="$emit('change', Types.DESTINATION)"
    >
      全部
    </div>
  </div>
</template>

<script>
import { string } from "@/utils/props-validator";
import { Types, localeTypes } from "../../../models/definitions";

export default {
  props: {
    category: string.enum(Types.SOURCE, Types.DESTINATION)
  },
  data() {
    return { Types };
  },
  filters: { localeTypes }
};
</script>

<style lang="less" scoped>
@import "../../../assets/vars.less";

.directory {
  user-select: none;
  .header {
    font-size: @local-font-size-lg;
    color: @local-title-color;
    display: flex;
    align-items: center;
    .title {
      margin-left: 16px;
    }
  }
  .group-label {
    font-size: @local-font-size-md;
    color: @local-title-color;
    margin: 60px 0 30px;
  }
  .group-item {
    margin-left: 24px;
    margin-top: 14px;
    padding: 8px 12px;
    border-radius: 3px;
    border: solid 1px transparent;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
      border-color: #ebebeb;
    }
    &.active {
      background: @blue-1;
      border-color: @blue-6;
    }
  }
}
</style>
