<template>
  <a-modal
    class="model-content-custom"
    :width="800"
    :footer="null"
    :centered="true"
    :mask-closable="false"
  >
    <ModelHeader slot="title" :img-src="connectorIcon" :title="connectorName" />
    <div class="model-body">
      <Setting :connector="connector" />
      <div class="delete-parent">
        <a-button
          block
          type="danger"
          class="delete"
          :loading="loading"
          @click="deleteConnector"
        >
          删除数据连接器
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import ModelHeader from "@/views/connections/components/modal-common-header";
import { Setting } from "@/views/connections/components/connector-details";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  components: { ModelHeader, Setting },
  props: {
    connector: {
      type: Object,
      required: true
    },
    $resolve: Function
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    connectorIcon() {
      return connectorList.getConnectorIcon(this.connector.id);
    },
    connectorName() {
      return connectorList.getConnectorName(this.connector.id) + " - 重新授权";
    }
  },
  methods: {
    deleteConnector() {
      this.$confirm({
        title: "确认删除",
        content: `确定要删除连接器「${this.connector.name}」吗？`,
        okType: "danger",
        onOk: async () => {
          try {
            this.loading = true;
            await connectorList.deleteConnector(this.connector.id);
            this.$message.success("删除成功");
            this.$resolve();
          } catch (err) {
            const resData = err.data || {};
            this.$notification.error({
              message: "操作失败",
              description: resData.msg
            });
          } finally {
            this.loading = false;
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.delete-parent {
  position: relative;
  margin: 0 auto;
  max-width: 680px;
  padding: 0 1.3em;
  padding-top: 20px;

  .delete {
    position: relative;
    top: -36px;
  }
}
</style>
