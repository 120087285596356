<template>
  <div class="group">
    <div class="header">
      <span class="header-left">{{ title }}</span>
      <a v-if="connectorList.length" class="header-right" @click="$emit('add')">
        <i class="ndl-icon-plus"></i>
        <span>添加{{ title }}</span>
      </a>
    </div>
    <div v-if="loading" class="body">
      <a-skeleton active />
    </div>
    <div v-else-if="!connectorList.length" class="body">
      <a-empty
        description=""
        :image="ImageEmpty"
        :image-style="{ height: '180px', margin: '60px' }"
      >
        <a-button
          icon="plus"
          type="link"
          style="font-size: 16px"
          @click="$emit('add')"
        >
          添加{{ title }}
        </a-button>
      </a-empty>
    </div>
    <Draggable
      v-else
      v-model="connectorList"
      :animation="300"
      @start="$emit('dragstart')"
      @end="$emit('dragend')"
      class="body"
    >
      <GroupItem
        v-for="(item, index) in connectorList"
        :key="index"
        :connector-id="item.id"
        @select="$emit('select', item)"
        @active="$emit('active', $event, item)"
      />
    </Draggable>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import ImageEmpty from "@/assets/images/ai_add.svg";
import GroupItem from "./GroupItem";

export default {
  components: { Draggable, GroupItem },
  props: {
    // 分组图标
    icon: String,
    // 分组名称
    title: String,
    loading: Boolean,
    // 连接器
    connectors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { ImageEmpty };
  },
  computed: {
    connectorList: {
      get() {
        return this.connectors;
      },
      set(connectors) {
        this.$emit("sort", connectors);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/vars.less";

.group {
  .header {
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      flex: 1 1 0;
      overflow: hidden;
      font-size: @local-font-size-lg;
      color: @local-title-color;
    }
    &-right {
      flex-shrink: 0;
      font-size: 18px;
    }
  }
  .ndl-icon-plus {
    margin-right: 4px;
  }
}
</style>
