<template>
  <VirtualList
    class="fields"
    data-key="fieldName"
    item-class="row"
    header-class="header-wrapper"
    :data-sources="sources"
    :data-component="rowComponent"
    :extra-props="{ config }"
  >
    <div slot="header" class="row">
      <span class="cell">字段名</span>
      <span class="cell minor">数据类型</span>
      <span class="cell">目标名</span>
      <span class="cell minor">主键</span>
      <span class="cell minor">同步标识</span>
    </div>
  </VirtualList>
</template>

<script>
import VirtualList from "@/components/virtual-list";
import FieldRow from "./FieldRow";
import flattenProperties from "./util-flatten-properties";

export default {
  components: { VirtualList },
  props: {
    // stream.jsonSchema.properties
    properties: Object,
    config: Object
  },
  computed: {
    rowComponent() {
      return FieldRow;
    },
    sources() {
      return flattenProperties(this.properties);
    }
  }
};
</script>

<style lang="less" scoped>
@import "./vars.less";

.fields {
  max-height: @fields-height;
  background: @fields-background;
  border-radius: @border-radius;
  margin: @margin;
  overflow: auto;
  color: @local-title-color;
  position: relative;

  /deep/ .header-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background: @fields-background;
    border-bottom: solid 1px #ebebeb;
  }

  /deep/ .row {
    height: @fields-row-height;
    display: flex;
    align-items: center;
  }

  /deep/ .row + .row {
    border-top: solid 1px #ebebeb;
  }
  /deep/ .cell {
    width: 0;
    padding-left: 16px;
    flex: 2 1 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /deep/ .cell.minor {
    flex-grow: 1;
  }
}
</style>
