import merge from "lodash/merge";

export default function(...params) {
  return merge(
    {
      sourceId: null,
      destinationId: null,
      namespaceDefinition: "destination",
      namespaceFormat: "${SOURCE_NAMESPACE}",
      operationIds: [],
      normalization: "",
      dbt: null,
      prefix: "ods_",
      syncCatalog: { streams: [] },
      schedule: null,
      status: null,
      resourceRequirements: {
        cpu_request: null,
        cpu_limit: null,
        memory_request: null,
        memory_limit: null
      }
    },
    ...params
  );
}
