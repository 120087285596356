<template>
  <div>
    <a-menu v-model="activeMenu" mode="horizontal">
      <a-menu-item v-for="(label, key) in tabs" :key="key">
        {{ label }}
      </a-menu-item>
    </a-menu>
    <template v-for="(label, key) in tabs">
      <slot v-if="activeMenu[0] === key" :name="key" />
    </template>
  </div>
</template>

<script>
const KEY_OVERVIEW = "overview";
const KEY_HISTORY = "history";
const KEY_SETTING = "setting";
const KEY_TRANSFORM = "transform";

export default {
  props: {
    enableTransform: Boolean
  },
  // 便于插槽中的组件切换 tab
  provide() {
    return { tabsVm: this };
  },
  data() {
    const tabs = {
      [KEY_OVERVIEW]: "概览",
      [KEY_HISTORY]: "同步历史",
      [KEY_SETTING]: "设置"
    };
    return {
      tabs,
      activeMenu: [KEY_OVERVIEW]
    };
  },
  watch: {
    enableTransform: {
      immediate: true,
      handler(bool) {
        const tabs = this.tabs;
        if (bool) {
          this.$set(tabs, KEY_TRANSFORM, "数据转换");
        } else {
          this.$delete(tabs, KEY_TRANSFORM);
        }
      }
    }
  },
  methods: {
    // 供插槽中的子组件调用，实现切换 tab 需求
    showOverview() {
      this.activeMenu = [KEY_OVERVIEW];
    },
    showHistory() {
      this.activeMenu = [KEY_HISTORY];
    },
    showSetting() {
      this.activeMenu = [KEY_SETTING];
    },
    showTransform() {
      this.activeMenu = [KEY_TRANSFORM];
    }
  }
};
</script>

<style lang="less" scoped>
.ant-menu {
  margin-bottom: 60px;
  border-bottom: none;
  /deep/ .ant-menu-item {
    margin-right: 40px;
  }
}
</style>
