<template>
  <div class="selector">
    <h1>从已有的{{ category | localeTypes }}中选择</h1>
    <Exists
      :category="category"
      :value="value"
      :expand-all="expandAll"
      @input="$listeners.input"
    />
    <h1>或添加一个{{ category | localeTypes }}</h1>
    <Create
      ref="creator"
      :category="category"
      :value="value"
      @input="$listeners.input"
      @update="onConnectorUpdate"
    />
  </div>
</template>

<script>
import { string } from "@/utils/props-validator";
import { Types, localeTypes } from "@/views/connections/models/connectors";

import Exists from "./Exists";
import Create from "./Create";

export default {
  components: { Exists, Create },
  props: {
    category: string.enum(Types.SOURCE, Types.DESTINATION),
    expandAll: Boolean,
    // connector id
    value: [String, Number]
  },
  filters: { localeTypes },
  methods: {
    async submit() {
      if (this.value) {
        return;
      }
      await this.$refs.creator.create();
    },
    // id 或 connector 其中一个有效时通过校验
    onConnectorUpdate(connector) {
      this.$emit("validate", Boolean(connector || this.value));
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";
@gutter: 10px;

.selector {
  h1 {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin: 120px 0 30px;
    color: @local-title-color;
  }
}
</style>
