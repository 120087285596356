<template>
  <div class="conditions">
    <Condition
      :class="[
        'condition',
        all.status === current.status ? 'condition-selected' : ''
      ]"
      :show-hover-bg="false"
      @click="select(all)"
    >
      <span>{{ all.title }}</span>
      <a-icon type="caret-up" class="condition-selected-icon" />
    </Condition>

    <JobTag
      v-for="item in conditions"
      :class="[
        'condition',
        item.status === current.status ? 'condition-selected' : ''
      ]"
      :key="item.status"
      :status="item.status"
      @click="select(item)"
    >
      <a-icon type="caret-up" class="condition-selected-icon" />
    </JobTag>
  </div>
</template>

<script>
import { localeStatus } from "@/views/connections/components/job-status";
import Condition from "@/views/connections/components/common-btn";
import JobTag from "@/views/connections/components/job-tag";

const all = { status: "", title: "全部" };
const conditions = [
  { status: "normal", title: localeStatus("normal") },
  { status: "succeeded", title: localeStatus("succeeded") },
  { status: "failed", title: localeStatus("failed") },
  { status: "running", title: localeStatus("running") }
];

export default {
  components: { Condition, JobTag },
  data() {
    return {
      all: all,
      current: all,
      conditions: conditions
    };
  },
  created() {
    this.select(this.current);
  },
  methods: {
    select(item) {
      this.current = item;
      this.$parent.$emit("filter", item.status);
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

.conditions {
  display: flex;
}
.condition {
  width: 86px;
  padding: 3px 12px;
  font-size: 14px;
  font-weight: 400;
  color: @blue-6;
  background-color: @blue-1;
  position: relative;
  cursor: pointer;

  &-selected-icon {
    margin: 0;
    position: absolute;
    bottom: -30px;
    opacity: 0;
    transition: bottom 0.3s, opacity 0.3s;
  }
  &-selected,
  &:hover {
    .condition-selected-icon {
      bottom: -15px;
      opacity: 1;
    }
  }
}
</style>
