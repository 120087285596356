<template>
  <Container
    class="con"
    :show-hover-bg="false"
    :style="statusStyle"
    @click="$event => $emit('click', $event)"
  >
    <span
      v-if="iconType === Types.FONT"
      :class="[`ndl-icon-${statusIcon}`, 'icon']"
    ></span>
    <a-icon v-else class="icon" :type="statusIcon" />
    <span>{{ statusLabel }}</span>
    <slot></slot>
  </Container>
</template>

<script>
import {
  localeStatus,
  colorPresets
} from "@/views/connections/components/job-status";
import Container from "@/views/connections/components/common-btn";

/**
 * 将 RGB(A)颜色值 转为 16 进制
 */
function colorHex(color) {
  const reg = /^(rgb|RGB)/;
  let strHex = "#";
  if (reg.test(color)) {
    const colorArr = color.replace(/(?:\(|\)|rgb|RGB|a|A)*/g, "").split(",");
    for (let i = 0; i < 3; i++) {
      let hex = Number(colorArr[i]).toString(16);
      if (hex === "0") hex += hex;
      strHex += hex;
    }
    if (colorArr.length > 3) {
      strHex += Number((255 * parseFloat(colorArr[3])).toFixed(0)).toString(16);
    }
    return strHex;
  }
  return String(color);
}

const Types = {
  FONT: "font",
  ICON: "icon"
};

const iconMap = {
  normal: { type: Types.FONT, icon: "info" },
  pending: { type: Types.FONT, icon: "clock" },
  running: { type: Types.FONT, icon: "activity" },
  incomplete: { type: Types.FONT, icon: "alert-triangle" },
  failed: { type: Types.FONT, icon: "x-circle" },
  succeeded: { type: Types.ICON, icon: "check-circle" },
  cancelled: { type: Types.FONT, icon: "minus-circle" }
};

export default {
  components: { Container },
  props: {
    status: String
  },
  data() {
    return { Types };
  },
  computed: {
    iconType() {
      const iconInfo = iconMap[this.status] || {};
      return iconInfo.type || Types.FONT;
    },
    statusIcon() {
      const iconInfo = iconMap[this.status] || {};
      return iconInfo.icon || "info";
    },
    statusLabel() {
      return localeStatus(this.status) || "未同步";
    },
    statusStyle() {
      const color = colorHex(colorPresets[this.status] || colorPresets.gray);
      const style = {
        color: color,
        backgroundColor: color + "20"
      };
      return style;
    }
  }
};
</script>

<style lang="less" scoped>
.con {
  padding: 2px 8px;
  font-size: 12px;
  cursor: default;
}
.icon {
  margin-right: 2px;
}
</style>
