<template>
  <a-modal
    class="model-content-custom"
    :width="800"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="confirmLoading"
    @ok="create"
  >
    <ModelHeader slot="title" :img-src="icon" :title="name" />
    <div class="model-body-small">
      <ConnectorEditor
        ref="editor"
        v-model="connector"
        :definition-id="definitionId"
      />
    </div>
  </a-modal>
</template>

<script>
import ModelHeader from "@/views/connections/components/modal-common-header";
import ConnectorEditor from "@/views/connections/components/connector-editor";
import { definitionList } from "@/views/connections/models/definitions";
import { connectorList } from "@/views/connections/models/connectors";

export default {
  components: { ModelHeader, ConnectorEditor },
  props: {
    category: {
      type: String,
      required: true
    },
    definitionId: {
      type: String,
      required: true
    },
    $resolve: Function
  },
  data() {
    return {
      confirmLoading: false,
      connector: {
        name: ""
      }
    };
  },
  watch: {
    definitionId: {
      immediate: true,
      handler: function() {
        this.connector.name = definitionList.getName(this.definitionId);
      }
    }
  },
  computed: {
    icon() {
      return definitionList.getIcon(this.definitionId);
    },
    name() {
      return definitionList.getName(this.definitionId) + " - 授权";
    }
  },
  methods: {
    async create() {
      if (!this.$refs.editor.validate()) {
        return;
      }
      try {
        this.confirmLoading = true;
        await connectorList.saveConnector({
          ...this.connector,
          category: this.category,
          definitionId: this.definitionId
        });
        this.$success({
          title: (
            <span style="font-size:17px;font-weight:700;">
              连接器创建成功！
            </span>
          ),
          content: (
            <p>
              已经可以开始同步数据了！数据同步可能需要等待一段时间，这期间不影响您的平台操作.
            </p>
          )
        });
        this.$resolve();
      } catch (err) {
        const resData = err.data || {};
        this.$notification.error({
          message: "操作失败",
          description: resData.msg
        });
      } finally {
        this.confirmLoading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";
</style>
